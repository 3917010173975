import useUserSetting from '@shared/hooks/src/useUserSetting'
import { useMe } from '@shared/providers/src/MeProvider'
import { UserSettingName } from '@shared/utils'

import { StarFilledIcon, StarOutlinedIcon } from '@icons'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui-components'
import LinearProgress from '@components/LinearProgress'
import CBO from '@components/TableCell/CBO'

import { usePriorityToggle } from './CBOsSupportMapDialog.hooks'

export default function StateCBOsDetailsDialog({ state, cbos = [], open, onClose }) {
  const me = useMe()
  const accessRights = useUserSetting(me, UserSettingName.AccessRights)
  const canToggle = me.superAdmin || accessRights?.value?.cbo_rule_set === true

  const toggle = usePriorityToggle(state)

  const onToggle = (cbo) => toggle.mutate(cbo)

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <LinearProgress loading={toggle.isPending} />
      <DialogTitle onClose={onClose}>State: {state}</DialogTitle>
      <DialogContent dividers>
        <Table size="small">
          <TableHead sx={{ backgroundColor: 'primary.100' }}>
            <TableRow>
              <TableCell width={80}>ID</TableCell>
              <TableCell>CBO</TableCell>
              <TableCell>Services</TableCell>
              <TableCell width={80} align="center">
                Priority
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cbos.length === 0 && <Empty />}
            {cbos.length > 0 &&
              cbos.map((cbo) => (
                <CBORow key={cbo.id} data={cbo} onToggle={canToggle ? () => onToggle(cbo) : undefined} isToggling={toggle.isPending} />
              ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  )
}

function CBORow({ data, isToggling, onToggle }) {
  return (
    <TableRow>
      <TableCell>
        <Typography variant="body2">{data.id}</Typography>
      </TableCell>
      <TableCell>
        <CBO small data={data}>
          <Typography variant="body2">
            <Typography variant="body2" component="span" color="text.secondary" sx={{ mr: 1 }}>
              Counties:
            </Typography>
            {data.counties?.join(', ') || 'Fully Covered'}
          </Typography>
        </CBO>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          <Typography variant="body2" component="span" color="text.secondary" sx={{ mr: 1 }}>
            Services:
          </Typography>
          {data.services.join(', ') || '—'}
        </Typography>
      </TableCell>
      <TableCell align="center" onClick={(e) => e.stopPropagation()}>
        {data.isFullyCovered && (
          <IconButton edge="end" color="warning" disabled={isToggling} onClick={onToggle}>
            {data.prioritized ? <StarFilledIcon /> : <StarOutlinedIcon />}
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}

function Empty() {
  return (
    <TableRow sx={{ height: 100 }}>
      <TableCell colSpan={4}>
        <Stack direction="row" justifyContent="center" spacing={1}>
          <Typography variant="h4" fontWeight="normal" color="text.secondary">
            No data to display
          </Typography>
        </Stack>
      </TableCell>
    </TableRow>
  )
}
