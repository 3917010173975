import { useCallback, useEffect } from 'react'
import { useEventListener, useLocalStorage } from 'usehooks-ts'

import { Logger } from '@shared/utils'

const LIMIT = 10
const SUBLIMIT = 5
const log = Logger('usePagesHistory')

export const PageHistoryType = {
  Patient: 'patient',
  Lab: 'lab',
  Rx: 'rx',
  Encounter: 'encounter',
}

// Free up space by removing the oldest history items
const freeUpSpaceForPagesHistory = (setHistory) => {
  try {
    setHistory((prev) => {
      if (prev.length === 0) return prev
      // Remove the oldest item (last in the array)
      const newHistory = prev.slice(0, -1)
      log.warn('Removing oldest history item to free up space')
      return newHistory
    })
    return true
  } catch (e) {
    log.error('Failed to free up space for pages history:', e)
    return false
  }
}

export default function usePagesHistory() {
  const [history, setHistory] = useLocalStorage('pages-history-v1', [])

  const push = useCallback((items, type, data, limit) => {
    const id = `${type}-${data.id}`
    const createdAt = new Date().toISOString()

    let item = items.find((item) => item.id === id)
    if (item) {
      item = { ...item, createdAt, data }
    } else {
      item = { id, createdAt, type, data }
    }

    const history = [item, ...items.filter((item) => item.id !== id)]

    if (history.length > limit) history.pop()

    return { history, item }
  }, [])

  const add = useCallback(
    (type, data, subtype, subdata) => {
      const attemptToAdd = () => {
        try {
          setHistory((prev) => {
            const { history, item } = push(prev, type, data, LIMIT)

            if (subtype && subdata) {
              const { history: children } = push(item.children || [], subtype, subdata, SUBLIMIT)
              item.children = children
            }

            return history
          })
          return true
        } catch (e) {
          if (e.name === 'QuotaExceededError') {
            log.warn('LocalStorage quota exceeded while adding to pages history')
            const freed = freeUpSpaceForPagesHistory(setHistory)
            if (freed) {
              log.warn('Retrying to add to pages history after freeing up space')
              return attemptToAdd()
            } else {
              log.warn('Could not free up space for pages history')
            }
          } else {
            log.error('Error adding to pages history:', e)
          }
          return false
        }
      }

      attemptToAdd()
    },
    [push, setHistory]
  )

  const clear = useCallback(() => {
    setHistory([])
  }, [setHistory])

  return { history, add, clear }
}

export function usePagesHistoryListener(type, data, subtype, subdata) {
  const { add } = usePagesHistory()

  useEffect(() => {
    const hasData = type && data
    const hasSubdata = subtype && subdata

    if ((hasData && !subtype) || (hasData && hasSubdata)) {
      log.debug('Add to pages history as effect', { type, data, subtype, subdata })
      add(type, data, subtype, subdata)
    }
  }, [add, data, subdata, subtype, type])

  // Add item to the history when returns back to the page
  useEventListener('visibilitychange', () => {
    const isVisible = !document.hidden
    if (isVisible) {
      log.debug('Add to pages history as visibilitychange', { type, data, subtype, subdata })
      add(type, data, subtype, subdata)
    }
  })
}
