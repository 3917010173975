import toast from 'react-hot-toast'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { DesktopTimePicker } from '@mui/x-date-pickers-pro'

import { handleError, includesOneOfErrorMessages } from '@shared/utils'

import { DeleteOutlinedIcon } from '@icons'
import { Box, Button, Collapse, FormHelperText, IconButton, LoadingButton, Stack, Typography } from '@mui-components'

import { appointmentErrors } from '../../AvailableItems'

export const AvailabilityToggleGroup = ({ value, onChange, disabled }) => {
  return (
    <ToggleButtonGroup
      fullWidth
      color="primary"
      value={value}
      exclusive
      disabled={disabled}
      onChange={(e, type) => {
        if (!type) return
        onChange(type)
      }}
      aria-label="Availability Type"
      size="small"
    >
      <ToggleButton value="availability">Appointment</ToggleButton>
      <ToggleButton value="adminTime">Admin</ToggleButton>
      {/* <ToggleButton value="ooo">Out Of Office</ToggleButton> */}
    </ToggleButtonGroup>
  )
}

export const TimeRangePicker = ({ readOnly, disabled, start, end, onStartChange, onEndChange, errors, touched, isLoading }) => {
  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center" gap={2}>
        <Typography sx={{ whiteSpace: 'nowrap' }}>{start ? start.format('ll') : ''}</Typography>

        <DesktopTimePicker
          disableOpenPicker
          value={start}
          onChange={onStartChange}
          readOnly={readOnly}
          disabled={!readOnly && (isLoading || disabled)}
          minutesStep={10}
          slotProps={{
            textField: {
              id: 'start',
              autoFocus: !readOnly,
              variant: 'standard',
              name: 'start',
              error: (touched.start && Boolean(errors.start)) || Boolean(errors.range),
              helperText: touched.start && errors.start,
            },
          }}
          sx={{ width: 90 }}
        />
        <Typography>to</Typography>
        <DesktopTimePicker
          disableOpenPicker
          value={end}
          onChange={onEndChange}
          readOnly={readOnly}
          disabled={!readOnly && (isLoading || disabled)}
          minutesStep={10}
          slotProps={{
            textField: {
              id: 'end',
              variant: 'standard',
              name: 'end',
              error: (touched.end && Boolean(errors.end)) || Boolean(errors.range),
              helperText: touched.end && errors.end,
            },
          }}
          sx={{ width: 90 }}
        />
      </Stack>
      <Collapse in={Boolean(errors.range)} unmountOnExit>
        <FormHelperText error sx={{ textAlign: 'center' }}>
          {errors.range}
        </FormHelperText>
      </Collapse>
    </Stack>
  )
}

export const ActionButtons = ({ disabled, isEditing, onDelete, onCancel, isLoading, isRemoving }) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%" spacing={1}>
      {isEditing && (
        <Box sx={{ flex: '1 1 auto' }}>
          <IconButton color="error" onClick={onDelete} disabled={isLoading || disabled}>
            <DeleteOutlinedIcon style={{ fontSize: 21 }} />
          </IconButton>
        </Box>
      )}
      <Button onClick={onCancel} color="error" variant="text" disabled={isLoading}>
        Cancel
      </Button>
      <LoadingButton type="submit" color="primary" variant="contained" disabled={isRemoving || disabled} loading={isLoading}>
        {isEditing ? 'Update' : 'Create'}
      </LoadingButton>
    </Stack>
  )
}

export const handleSubmitErrors = (e, setFieldError, isEditing, resetForm, setIsNotice) => {
  if (includesOneOfErrorMessages(e, ['Date time range is overlapping with other availability'])) {
    setFieldError('range', 'You already have availability for these times')
  } else if (
    includesOneOfErrorMessages(e, ['Date time range is outside of business hours', 'There are availabilities outside business hours'])
  ) {
    setFieldError('range', 'Availability must be within business hours')
  } else if (includesOneOfErrorMessages(e, ['Date time range is overlapping with Blackout Period'])) {
    setFieldError('range', 'Availability cannot overlap with Blackout Period')
  } else if (includesOneOfErrorMessages(e, ['Given range is fully within a blackout period'])) {
    setFieldError('range', 'Availability cannot be within Blackout Period')
  } else if (includesOneOfErrorMessages(e, ['Max hours allowed per week exceeded'])) {
    setFieldError('range', 'You have reached the maximum allowed working hours per week')
  } else if (includesOneOfErrorMessages(e, ['Availability is in the past'])) {
    setFieldError('range', 'Changes to past availabilities are restricted')
  } else if (includesOneOfErrorMessages(e, ['Availability cannot be scheduled more than 8 weeks ahead'])) {
    setFieldError('range', 'Cannot schedule beyond 8 weeks')
  } else if (includesOneOfErrorMessages(e, appointmentErrors)) {
    if (isEditing) resetForm()
    setIsNotice(true)
  } else {
    handleError(e)
  }
}

export const handleRemoveErrors = (e, setIsNotice) => {
  if (includesOneOfErrorMessages(e, appointmentErrors)) {
    setIsNotice(true)
  } else if (includesOneOfErrorMessages(e, ['Availability is in the past'])) {
    toast.error('Sorry, changes to past availabilities are restricted.')
  } else {
    handleError(e)
  }
}
