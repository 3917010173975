import { useState } from 'react'

import { Button } from '@mui-components'

import PhotoIdReminderDialog from './PhotoIdReminderDialog'

export default function PhotoIdReminderButton({ patient, disabled, onSubmit }) {
  const [open, setOpen] = useState(false)

  const handleSubmit = (message) => onSubmit(message).then(() => setOpen(false))

  return (
    <>
      <Button size="small" variant="outlined" disabled={disabled} onClick={() => setOpen(true)}>
        Message Patient
      </Button>
      <PhotoIdReminderDialog patient={patient} open={open} onClose={() => setOpen(false)} onSubmit={handleSubmit} />
    </>
  )
}
