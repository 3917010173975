import { useCallback, useEffect } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useEffectOnce, useInterval, useLocalStorage } from 'usehooks-ts'

import AuthApi from '@shared/services/src/Auth.api'
import { handleErrorSilently, Logger } from '@shared/utils'

const log = Logger('KeepSessionAlive')

// Get the inactivity timeout from the local storage or default to 5 minutes
const inactivityTimeout = (localStorage.getItem('keep-alive-extension-seconds') || 300) * 1000

/**
 * Handles the user auth session and keeping it alive if required.
 */
export default function KeepSessionAlive() {
  const keepAlive = useKeepAlive()

  // Call the prolongate session endpoint when mounted
  useEffectOnce(() => {
    keepAlive()
  })

  // Call the prolongate session endpoint every interval
  useInterval(() => {
    keepAlive()
  }, inactivityTimeout)

  return null
}

/**
 * Track user activity and extend the session when keystroke detected.
 */
export function TrackUserActivity() {
  const [lastExtensionTime, setLastExtensionTime] = useLocalStorage('last-extension-time', Date.now())
  const keepAlive = useKeepAlive()

  const handleKeepAlive = useCallback(() => {
    return keepAlive().then(() => {
      setLastExtensionTime(Date.now())
      log.info(
        `Extend user session since keystroke detected and ${(inactivityTimeout / 1000).toFixed()} seconds passed since last extension`
      )
    })
  }, [keepAlive, setLastExtensionTime])

  useEffect(() => {
    const handleKeyDown = (event) => {
      const target = event.target
      if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA' || target.classList.contains('tiptap')) {
        handleKeepAlive()
        document.removeEventListener('keydown', handleKeyDown)
      }
    }

    // Calculate the remaining time until the session can be extended
    const timeout = inactivityTimeout - (Date.now() - lastExtensionTime)
    const timeoutId = setTimeout(() => document.addEventListener('keydown', handleKeyDown), timeout)

    return () => {
      clearTimeout(timeoutId)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeepAlive, lastExtensionTime])

  return null
}

const useKeepAlive = () => {
  const keepAlive = useMutation({
    mutationFn: () => AuthApi.keepAlive(),
  })

  return useCallback(() => {
    return keepAlive.mutateAsync().catch(handleErrorSilently)
  }, [keepAlive])
}
