import dayjs from 'dayjs'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { DesktopDateRangePicker, StaticDateRangePicker } from '@mui/x-date-pickers-pro'

import { getTestId } from '@shared/utils'

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid, Stack } from '@mui-components'

import DateRangeShortcuts from './DateRangeShortcuts'

DateRangeDialog.propTypes = {
  /** If `true`, the dialog is open */
  open: PropTypes.bool.isRequired,

  /** Callback fired when the dialog is closed */
  onClose: PropTypes.func.isRequired,

  /** Initial Dayjs values of range */
  initialValue: PropTypes.array,

  /** Callback fired when the value changes */
  onChange: PropTypes.func.isRequired,
}

/**
 * Date range dialog
 *
 * @example
 * <DateRangeDialog
 *    open={open}
 *    onClose={() => setOpen(false)}
 *    initialValue={value}
 *    onChange={setValue}
 *  />
 */
export default function DateRangeDialog({ open, onClose, initialValue, onChange, ...rest }) {
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <Content onClose={onClose} initialValue={initialValue} onChange={onChange} {...rest} />
    </Dialog>
  )
}

// Separate state from dialog, so it resets when dialog is closed
function Content({ onClose, initialValue = [], onChange, ...rest }) {
  const testId = getTestId(rest, 'date-range-dialog')

  const formik = useFormik({
    initialValues: {
      startDate: initialValue[0] || null,
      endDate: initialValue[1] || null,
    },
    validationSchema,
    onSubmit: ({ startDate, endDate }) => {
      onChange([startDate, endDate])
      onClose()
    },
  })

  const rangeError = Boolean(formik.errors.range)

  const startError = formik.touched.startDate && formik.errors.startDate
  const startFieldError = Boolean(startError) || rangeError

  const endError = formik.touched.endDate && formik.errors.endDate
  const endFieldError = Boolean(endError) || rangeError

  const value = [formik.values.startDate, formik.values.endDate]
  const handleChange = ([startDate, endDate]) => {
    formik.setFieldValue('startDate', startDate)
    formik.setFieldValue('endDate', endDate)
  }

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <DialogTitle>Select date range</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <DateRangeShortcuts value={value} onChange={handleChange} data-testid={`${testId}-shortcuts`} />
          </Grid>
          <Grid item xs={12} sm={9}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <StaticDateRangePicker displayStaticWrapperAs="desktop" value={value} onChange={handleChange} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <DesktopDateRangePicker
                disableOpenPicker
                value={value}
                onChange={handleChange}
                slotProps={{
                  textField: ({ position }) => {
                    const name = position === 'start' ? 'startDate' : 'endDate'
                    return {
                      fullWidth: true,
                      InputLabelProps: { shrink: true },
                      id: name,
                      name,
                      error: position === 'start' ? startFieldError : endFieldError,
                      helperText: position === 'start' ? startError : endError,
                      inputProps: { 'data-testid': `${testId}-${name}` },
                    }
                  },
                }}
              />
              {formik.errors.range && <FormHelperText error>{formik.errors.range}</FormHelperText>}
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error" data-testid={`${testId}-cancel`}>
          Cancel
        </Button>
        <Button type="submit" variant="contained" data-testid={`${testId}-submit`}>
          Ok
        </Button>
      </DialogActions>
    </form>
  )
}

const validationSchema = Yup.object()
  .shape({
    startDate: Yup.object()
      .nullable()
      .required('Required')
      .test('invalid-start', 'Invalid Date', (v) => dayjs.isDayjs(v)),
    endDate: Yup.object()
      .nullable()
      .required('Required')
      .test('invalid-end', 'Invalid Date', (v) => dayjs.isDayjs(v)),
  })
  .test({
    name: 'validate-range',
    test: function ({ startDate, endDate }) {
      if (dayjs.isDayjs(startDate) && dayjs.isDayjs(endDate)) {
        if (startDate.isAfter(endDate)) {
          return this.createError({ path: 'range', message: 'Start must be before End' })
        }
      }
      return true
    },
  })
