import { useMemo, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import dayjs from 'dayjs'
import { useLocalStorage } from 'usehooks-ts'

import { mapRailsTimezoneToJS } from '@shared/utils'

import AppointmentDetailsModal from '@pages/Appointments/AppointmentDetailsModal'
import { Fade, Stack, Typography } from '@mui-components'
import CalendarDate from '@components/CalendarDate'
import LinearProgress from '@components/LinearProgress'

import AppointmentForm from './AppointmentForm'
import AvailabilityForm from './AvailabilityForm'
import { FullCalendar, IndicatorGuide, ModeToggleGroup, ScheduleTypeToggleGroup, Timing } from './Calendar.components'
import { useCalendarData, useDate } from './Calendar.hooks'
import { CalendarEvent, findAvailability, Mode, PastEntriesErrorString, ScheduleType } from './Calendar.utils'
import CalendarUpdatesTour from './CalendarUpdatesTour'

export default function Calendar({ user, isLoading: isLoadingFromOutside = false, readOnly = false }) {
  const calendarRef = useRef(null)

  const providerId = user?.provider.id
  const timezone = user?.provider.timezone

  const [date, setDate] = useDate(timezone)
  const [view, setView] = useState('week')
  const [mode, setMode] = useLocalStorage('scheduling-mode', Mode.Availability)
  const [scheduleType, setScheduleType] = useLocalStorage('scheduling-type', ScheduleType.All)

  const [popoverPosition, setPopoverPosition] = useState(null)

  const [selectedAvailability, setSelectedAvailability] = useState(null)
  const [isUpdateAvailabilityOpen, setIsUpdateAvailabilityOpen] = useState(false)

  const [selectedAdHocAppointment, setSelectedAdHocAppointment] = useState(null)
  const [isAppointmentFormOpen, setIsAppointmentFormOpen] = useState(false)

  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null)
  const [isAppointmentDetailsOpen, setIsAppointmentDetailsOpen] = useState(false)

  const { events, isLoading, businessHours, completedEncounters, availabilities, adminTimes } = useCalendarData({ user, date, scheduleType })

  const handleSelect = ({ start, end, jsEvent }) => {
    const setSelectedDate = mode === Mode.Availability ? setSelectedAvailability : setSelectedAdHocAppointment
    const setFormOpen = mode === Mode.Availability ? setIsUpdateAvailabilityOpen : setIsAppointmentFormOpen

    setPopoverPosition({ top: jsEvent.clientY, left: jsEvent.clientX })
    setSelectedDate({ start, end })
    setFormOpen(true)
  }

  const handleClick = ({ event, jsEvent }) => {
    const id = event.extendedProps?.id
    const type = event.extendedProps?.type

    // Allow appointments to be viewed independently of the mode
    if (type === CalendarEvent.Appointment) {
      setSelectedAppointmentId(id)
      setIsAppointmentDetailsOpen(true)
      return
    }

    if (type === CalendarEvent.AdHocAppointment) {
      setPopoverPosition({ top: jsEvent.clientY, left: jsEvent.clientX })
      setSelectedAdHocAppointment({ id, start: event.start, end: event.end })
      setIsAppointmentFormOpen(true)
      return
    }

    // Prevent editing if in read-only mode
    if (readOnly) return

    // Prevent editing past entries
    const isInThePast = dayjs(event.end).isBefore(dayjs().tz(timezone))
    if (isInThePast) return toast.error(PastEntriesErrorString)

    // Allow editing of availability and admin time in availability mode
    if ([CalendarEvent.Availability, CalendarEvent.AdminTime].includes(type)) {
      setPopoverPosition({ top: jsEvent.clientY, left: jsEvent.clientX })
      setSelectedAvailability({ id, type, start: event.start, end: event.end })
      setIsUpdateAvailabilityOpen(true)
      return
    }
  }

  const availabilityEvents = useMemo(() => {
    return events.filter((event) => event.extendedProps.type === CalendarEvent.Availability)
  }, [events])

  const selectOverlap = mode === Mode.Appointment ? (event) => event.extendedProps.type === 'availability' : false

  const selectAllow = ({ start, end }) => {
    if (readOnly) return false

    // Prevent editing past entries
    const isInThePast = dayjs(end).isBefore(dayjs().tz(timezone))
    if (isInThePast) return false

    if (mode === Mode.Availability) return true

    if (mode === Mode.Appointment) {
      const hasAvailability = findAvailability({ start, end }, availabilityEvents)
      return Boolean(hasAvailability)
    }
  }

  return (
    <Fade in>
      <Stack spacing={1} flexGrow={1}>
        {!readOnly && <CalendarUpdatesTour />}

        <AvailabilityForm
          providerId={providerId}
          availability={selectedAvailability}
          open={isUpdateAvailabilityOpen}
          anchorPosition={popoverPosition}
          onClose={() => setIsUpdateAvailabilityOpen(false)}
        />
        <AppointmentForm
          readOnly={readOnly}
          providerId={providerId}
          timezone={timezone}
          appointment={selectedAdHocAppointment}
          availabilities={availabilityEvents}
          open={isAppointmentFormOpen}
          anchorPosition={popoverPosition}
          onClose={() => setIsAppointmentFormOpen(false)}
        />
        <AppointmentDetailsModal
          timezone={timezone}
          appointmentId={selectedAppointmentId}
          open={isAppointmentDetailsOpen}
          onClose={() => setIsAppointmentDetailsOpen(false)}
        />

        <Stack spacing={2}>
          <Stack direction="row" spacing={1} alignItems="flex-end" justifyContent="space-between">
            <Stack>
              <IndicatorGuide />
              <Typography variant="body2">
                Times shown in <b>Provider’s timezone {timezone ? `(${dayjs().tz(mapRailsTimezoneToJS(timezone)).format('z')})` : ''}</b>
              </Typography>
            </Stack>
            <Timing user={user} completedEncounters={completedEncounters} availabilities={availabilities} adminTimes={adminTimes} />
          </Stack>

          <Stack direction="row" spacing={4}>
            <ScheduleTypeToggleGroup value={scheduleType} onChange={setScheduleType} />
            {!readOnly && <ModeToggleGroup value={mode} onChange={setMode} />}
          </Stack>

          <CalendarDate
            date={date}
            onDateChange={setDate}
            view={view}
            onViewChange={(newView) => {
              setView(newView)
              calendarRef.current?.getApi()?.changeView(newView === 'day' ? 'timeGridDay' : 'timeGridWeek')
            }}
          />
        </Stack>

        <Stack sx={{ position: 'relative' }}>
          <LinearProgress loading={isLoading || isLoadingFromOutside} />
          <FullCalendar
            calendarRef={calendarRef}
            readOnly={readOnly}
            date={date}
            events={events}
            businessHours={businessHours}
            selectAllow={selectAllow}
            selectOverlap={selectOverlap}
            onSelect={handleSelect}
            onClick={handleClick}
          />
        </Stack>
      </Stack>
    </Fade>
  )
}
