import { Box } from '@mui/material'

import { RoleGuard } from '@shared/providers/src/MeProvider'
import { UserRole } from '@shared/utils'

import { useMediaQuery } from '@mui-components'

import Availability from '../Availability'
import QPhrases from '../QPhrases'
import CBOSupportMap from './CBOSupportMap'
import Environment from './Environment'
import Mentions from './Mentions'
import Notifications from './Notifications'
import PagesHistory from './PagesHistory'
import PhoneCall from './PhoneCall'
import Profile from './Profile'
import Search from './Search'
import Settings from './Settings'
import SOPs from './SOPs'

export default function Content() {
  const matchDownSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <>
      {!matchDownSm && <Search />}
      <PagesHistory />
      <Box sx={{ width: '100%' }} />
      {!matchDownSm && <Environment />}
      <QPhrases />
      <RoleGuard prohibited={[UserRole.CBO, UserRole.Pharmacy, UserRole.Manager]}>
        <PhoneCall />
      </RoleGuard>
      <RoleGuard prohibited={[UserRole.CBO]}>
        <CBOSupportMap />
        <SOPs />
      </RoleGuard>
      <RoleGuard allowed={[UserRole.Provider]}>
        <Availability />
        <Settings />
      </RoleGuard>
      <Mentions />
      <Notifications />
      <Profile />
    </>
  )
}
