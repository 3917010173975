import { useState } from 'react'
import { useDndMonitor, useDraggable } from '@dnd-kit/core'
import { useTheme } from '@mui/material/styles'

export function useDraggableWidget() {
  const theme = useTheme()

  const [position, setPosition] = useState({
    x: window.innerWidth - 170 - 8,
    y: theme.mixins.toolbar.height + 8,
  })

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: 'phone-call-widget',
    activationConstraint: {
      delay: 250,
      distance: 3,
    },
  })

  useDndMonitor({
    onDragEnd(event) {
      const { delta } = event
      setPosition((prevPosition) => ({
        x: prevPosition.x + delta.x,
        y: prevPosition.y + delta.y,
      }))
    },
  })

  const style = transform ? { transform: `translate3d(${transform.x}px, ${transform.y}px, 0)` } : undefined

  return { position, setNodeRef, listeners, attributes, style }
}
