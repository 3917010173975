import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'
import { keyToString } from '@shared/utils'

// Message Mentions Query Key factory
export const MessageMentionsKeys = {
  // ['message-mentions']
  all: ['message-mentions'],

  // ['message-mentions', userId]
  user: (userId) => keyToString([...MessageMentionsKeys.all, userId]),

  // ['message-mentions', userId, 'lists']
  lists: (userId) => [...MessageMentionsKeys.user(userId), 'lists'],

  // ['message-mentions', userId, 'lists', { ...query }]
  list: (userId, query) => [...MessageMentionsKeys.lists(userId), query],
}

const MessageMentionsApi = {
  list: (userId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/users/${userId}/message_mentions`, query }, { arrayFormat: 'bracket' }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
}

export default MessageMentionsApi
