import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'
import { keyToString } from '@shared/utils'

// Appointments Query Key factory
export const AppointmentsKeys = {
  // ['appointments']
  lists: ['appointments', 'lists'],

  // ['appointments', 'lists', { ...query }]
  list: (query) => [...AppointmentsKeys.lists, query],
}

// Appointment Notes Query Key factory
export const AppointmentNotesKeys = {
  // ['appointment-notes', appointmentId]
  appointment: (appointmentId) => keyToString(['appointment-notes', appointmentId]),

  // ['appointment-notes', appointmentId, 'lists']
  lists: (appointmentId) => [...AppointmentNotesKeys.appointment(appointmentId), 'lists'],

  // ['appointment-notes', appointmentId, 'lists', { ...query }]
  list: (appointmentId, query) => [...AppointmentNotesKeys.lists(appointmentId), query],
}

// Provider appointments Query Key factory
export const ProviderAppointmentsKeys = {
  // ['provider-appointments']
  all: ['provider-appointments'],

  // ['provider-appointments', providerId]
  provider: (providerId) => keyToString([...ProviderAppointmentsKeys.all, providerId]),

  // ['provider-appointments', providerId, 'lists']
  lists: (providerId) => [...ProviderAppointmentsKeys.provider(providerId), 'lists'],

  // ['provider-appointments', providerId, 'lists', { ...query }]
  list: (providerId, query) => [...ProviderAppointmentsKeys.lists(providerId), query],

  previewSoapNote: (appointmentId) => keyToString(['appointment', appointmentId, 'preview', 'soap_note']),
  previewAvs: (appointmentId) => keyToString(['appointment', appointmentId, 'preview', 'avs']),

  labs: (appointmentId) => keyToString(['appointment', appointmentId, 'labs']),
  prescriptions: (appointmentId) => keyToString(['appointment', appointmentId, 'prescriptions']),
}

// Provider ad hoc appointments Query Key factory
export const ProviderAdHocAppointmentsKeys = {
  // ['provider-ad-hoc-appointments']
  all: ['provider-ad-hoc-appointments'],

  // ['provider-ad-hoc-appointments', providerId]
  provider: (providerId) => keyToString([...ProviderAdHocAppointmentsKeys.all, providerId]),

  // ['provider-ad-hoc-appointments', providerId, 'lists']
  lists: (providerId) => [...ProviderAdHocAppointmentsKeys.provider(providerId), 'lists'],

  // ['provider-ad-hoc-appointments', providerId, 'lists', { ...query }]
  list: (providerId, query) => [...ProviderAdHocAppointmentsKeys.lists(providerId), query],
}

const AppointmentsApi = {
  list: (query) =>
    api
      .get(QS.stringifyUrl({ url: '/appointments', query }, { arrayFormat: 'bracket' }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  getVoiceToken: (appointmentId, providerId) =>
    api.get(`/providers/${providerId}/appointments/${appointmentId}/phone_token`).then((resp) => resp.data),
  appointments: (providerId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/providers/${providerId}/appointments`, query }, { arrayFormat: 'bracket' }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  previewSoapNote: (appointmentId) => {
    return api.get(`/appointments/${appointmentId}/soap_note`).then((res) => res.data)
  },
  previewAvs: (appointmentId) => {
    return api.get(`/appointments/${appointmentId}/after_visit_summary`).then((res) => res.data)
  },
  labs: (appointmentId, query) => {
    return api
      .get(QS.stringifyUrl({ url: `/appointments/${appointmentId}/labs`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? [])
  },
  prescriptions: (appointmentId, query) => {
    return api
      .get(QS.stringifyUrl({ url: `/appointments/${appointmentId}/prescriptions`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? [])
  },
  notes: (appointmentId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/appointments/${appointmentId}/notes`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  createNote: (appointmentId, message) =>
    api
      .post(`/appointments/${appointmentId}/notes`, { message })
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  // Provider ad hoc appointments
  adHocList: (providerId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/providers/${providerId}/ad_hoc_appointments`, query }, { arrayFormat: 'bracket' }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  createAdHoc: (providerId, data) =>
    api
      .post(`/providers/${providerId}/ad_hoc_appointments`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  updateAdHoc: (providerId, appointmentId, data) =>
    api
      .patch(`/providers/${providerId}/ad_hoc_appointments/${appointmentId}`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  removeAdHoc: (providerId, appointmentId) =>
    api
      .delete(`/providers/${providerId}/ad_hoc_appointments/${appointmentId}`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  adHocNotes: (providerId, appointmentId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/providers/${providerId}/ad_hoc_appointments/${appointmentId}/notes`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  createAdHocNote: (providerId, appointmentId, message) =>
    api
      .post(`/providers/${providerId}/ad_hoc_appointments/${appointmentId}/notes`, { message })
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  start: (providerId, appointmentId) => {
    return api
      .post(`/providers/${providerId}/appointments/${appointmentId}/start`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {})
  },
  completeCall: (providerId, appointmentId) => {
    return api
      .post(`/providers/${providerId}/appointments/${appointmentId}/complete_call`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {})
  },
  markAsCompleted: (providerId, appointmentId) => {
    return api
      .post(`/providers/${providerId}/appointments/${appointmentId}/complete`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {})
  },
  markAsMissed: (appointmentId) => {
    return api
      .post(`/appointments/${appointmentId}/set_missed`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {})
  },
  markAsCanceled: (appointmentId) => {
    return api
      .post(`/appointments/${appointmentId}/set_canceled`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {})
  },
  markAsFailed: (appointmentId, data) => {
    return api
      .post(`/appointments/${appointmentId}/fail`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {})
  },
  swapType: (providerId, appointmentId) => {
    return api
      .post(`/providers/${providerId}/appointments/${appointmentId}/create_swap_type`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {})
  },
}

export default AppointmentsApi
