import { useMemo, useState } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'

import useDialog from '@shared/hooks/src/useDialog'
import { RoleGuard, useMe } from '@shared/providers/src/MeProvider'
import { AppointmentScheduleTypes, AppointmentStatus, mapRailsTimezoneToJS, UserRole } from '@shared/utils'

import { AppointmentScheduleModal } from '@pages/Appointments'
import { AppointmentIcon, CloseOutlinedIcon, EditOutlinedIcon } from '@icons'
import { Button, Link, Stack, Typography } from '@mui-components'
import Property, { CBOProperty, ProviderProperty } from '@components/Details/Property'
import Section from '@components/Details/Section'
import Confirmation from '@components/Dialog/Confirmation'
import PatientAccessGuard from '@components/PatientAccessGuard'

import { Status } from '../../Appointments.utils'
import { useMarkAppointmentAsCanceled, useMarkAppointmentAsMissed } from '../AppointmentDetailsModal.hooks'

export default function DetailsSection({ appointment }) {
  const location = useLocation()
  const isEncounterPage = location.pathname.includes('encounters')
  const providerId = appointment?.provider?.id

  const [patientTime, providerTime] = useMemo(() => {
    const patientTime = getTiming(appointment?.user, appointment)
    const providerTime = getTiming(appointment?.provider, appointment)
    return [patientTime, providerTime]
  }, [appointment])

  return (
    <Section paper mini title="Details" action={<Actions appointment={appointment} />}>
      <Property label="Reason">
        <Stack direction="row" alignItems="center" spacing={1}>
          <AppointmentIcon type={appointment.type} />
          {isEncounterPage ? (
            <Typography>{appointment.visitReason}</Typography>
          ) : (
            <Link
              component={RouterLink}
              to={`/app/patients/${appointment.userId}/encounters/${appointment.encounterId}`}
              fontWeight="bold"
              target="_blank"
              rel="noopener noreferrer"
            >
              {appointment.visitReason}
            </Link>
          )}
        </Stack>
      </Property>
      <Property label="Status">
        <Status data={appointment} />
      </Property>
      <Property label="Patients's Time">{patientTime}</Property>
      <Property label="Provider's Time">{providerTime}</Property>
      {providerId && <ProviderProperty label="Provider" user={appointment.provider} />}
      <CBOProperty label="CBO" data={appointment.cbo} />
    </Section>
  )
}

DetailsSection.Loading = () => (
  <Section paper mini title="Details">
    <Property.Loading label="Reason" />
    <Property.Loading label="Status" />
    <Property.Loading label="Patients's Time" />
    <Property.Loading label="Provider's Time" />
    <ProviderProperty.Loading label="Provider" />
    <CBOProperty.Loading label="CBO" />
  </Section>
)

function Actions({ appointment }) {
  const canBeCanceled = [AppointmentStatus.Scheduled, AppointmentStatus.Waiting].includes(appointment.status)
  const canBeRescheduled =
    [AppointmentStatus.Scheduled].includes(appointment.status) && appointment.schedType === AppointmentScheduleTypes.Scheduled

  return (
    <Stack direction="row" spacing={1}>
      {canBeCanceled && (
        <RoleGuard prohibited={[UserRole.Manager, UserRole.Pharmacy, UserRole.CBO]}>
          <CancelButton appointment={appointment} />
        </RoleGuard>
      )}
      {canBeRescheduled && (
        <PatientAccessGuard patientId={appointment.userId}>
          <RescheduleButton appointment={appointment} />
        </PatientAccessGuard>
      )}
    </Stack>
  )
}

function RescheduleButton({ appointment }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button color="secondary" onClick={() => setOpen(true)} startIcon={<EditOutlinedIcon />}>
        Reschedule
      </Button>
      <AppointmentScheduleModal
        slide
        open={open}
        appointment={appointment}
        patient={appointment.user}
        encounterId={appointment.encounterId}
        onClose={() => setOpen(false)}
      />
    </>
  )
}

function CancelButton({ appointment }) {
  const me = useMe()
  const markAsMissed = useMarkAppointmentAsMissed()
  const markAsCanceled = useMarkAppointmentAsCanceled()

  const isPassed = dayjs().isAfter(appointment.start)
  const fn = isPassed ? markAsMissed : markAsCanceled

  const confirmCancel = useDialog({
    component: Confirmation,
    props: ({ close }) => ({
      title: isPassed ? 'Mark as missed' : 'Cancel appointment',
      description: `Are you sure you want to ${isPassed ? 'mark the appointment as missed' : 'cancel the appointment'}?`,
      rejectLabel: 'Close',
      confirmLabel: 'Confirm',
      onReject: () => close(),
      onConfirm: () => fn.mutateAsync(appointment.id).then(close),
    }),
  })

  if (isPassed && ![UserRole.Admin, UserRole.Provider].includes(me.role)) return null

  return (
    <Button
      color="error"
      startIcon={<CloseOutlinedIcon />}
      onClick={(e) => {
        e.stopPropagation()
        confirmCancel()
      }}
    >
      {isPassed ? 'Missed' : 'Cancel'}
    </Button>
  )
}

const getTiming = (user, appointment) => {
  if (!appointment) return '—'

  const tz = mapRailsTimezoneToJS(user?.timezone)
  const start = dayjs(appointment.start).tz(tz)

  let time
  if (appointment.duration) {
    time = `${start.format('LT')} - ${start.add(appointment.duration, 'minutes').format('LT z')}`
  } else {
    time = start.format('LT z')
  }

  return `${start.format('L')}, ${time}`
}
