import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import range from 'lodash/range'

import { MoreOutlinedIcon } from '@icons'
import {
  Box,
  Divider,
  FormControlLabel,
  List,
  ListItemButton,
  LoadingButton,
  Popover,
  Skeleton,
  Stack,
  Switch,
  Tab,
  Tabs,
  Typography,
} from '@mui-components'

import { useMentions } from './Mentions.hooks'

export default function MentionsPopover({ anchorEl, open = false, onClose }) {
  const [type, setType] = useState('received')
  const [unreplied, setUnreplied] = useState(true)

  const { data, isPending, fetchNextPage, hasNextPage, isFetchingNextPage } = useMentions({ type, unreplied: unreplied || undefined })

  const showLoading = isPending
  const showEmpty = !showLoading && data?.length === 0
  const showData = !showEmpty && data?.length > 0

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      slotProps={{
        paper: {
          sx: { width: '440px' },
          'data-testid': 'mentions-popover',
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <Stack direction="row" alignItems="center" sx={{ px: 3, py: 2 }}>
        <Typography variant="h4" sx={{ flex: '1 1 auto' }}>
          Mentions
        </Typography>
        <FormControlLabel
          label="Only show unreplied"
          value="start"
          labelPlacement="start"
          control={<Switch checked={unreplied} onChange={(e, checked) => setUnreplied(checked)} />}
        />
      </Stack>
      <Tabs value={type} onChange={(e, tab) => setType(tab)} sx={{ px: 2 }}>
        <Tab label="Incoming" value="received" data-testid="mentions-received-tab" />
        <Tab label="Outgoing" value="sent" data-testid="mentions-sent-tab" />
      </Tabs>
      <Divider />
      {showLoading && (
        <Content>
          {range(0, 4).map((item, i, data) => (
            <MentionItem.Loading key={item} divider={item < data.length - 1} />
          ))}
        </Content>
      )}
      {showEmpty && (
        <Box sx={{ p: 2 }} data-testid="mentions-empty">
          <Typography variant="h5" color="text.secondary" sx={{ height: 50, fontWeight: 'normal', textAlign: 'center' }}>
            Nothing to see here!
          </Typography>
        </Box>
      )}
      {showData && (
        <Content>
          {data.map((mention, i) => (
            <MentionItem key={mention.id} divider={i < data.length - 1} type={type} mention={mention} onClose={onClose} />
          ))}
          {hasNextPage && (
            <LoadingButton
              fullWidth
              loading={isFetchingNextPage}
              onClick={() => fetchNextPage()}
              endIcon={<MoreOutlinedIcon rotate={90} />}
              loadingPosition="end"
              sx={{ textTransform: 'none' }}
              data-testid="mentions-more"
            >
              more
            </LoadingButton>
          )}
        </Content>
      )}
    </Popover>
  )
}

function Content({ children }) {
  return (
    <Box sx={{ maxHeight: '360px', overflowY: 'auto' }}>
      <List disablePadding data-testid="mentions-list">
        {children}
      </List>
    </Box>
  )
}

function MentionItem({ divider, type, mention, onClose }) {
  const navigate = useNavigate()
  const testId = `mention-item-${mention.id}`

  return (
    <ListItemButton
      divider={divider}
      onClick={() => {
        navigate(`/app/patients/${mention.patientId}?patientTab=chat&messageId=${mention.replyMessageId}`)
        onClose()
      }}
      sx={{ transition: 'opacity 0.4s ease-in-out', opacity: mention.repliedAt ? 0.5 : 1 }}
      data-testid={`mention-item-${mention.id}`}
    >
      {type === 'received' && (
        <div>
          <Typography variant="body2" data-testid={`${testId}-content`}>
            <b>{mention.mentionee?.fullName || 'User'}</b> mentioned you in the patient's internal chat (MRN&nbsp;{mention.patientId})
          </Typography>
          {mention.createdAt && (
            <Typography color="text.secondary" variant="caption" data-testid={`${testId}-date`}>
              {dayjs(mention.createdAt).format('MMM D, hh:mm A')}
            </Typography>
          )}
        </div>
      )}
      {type === 'sent' && (
        <div>
          <Typography variant="body2" data-testid={`${testId}-content`}>
            You mentioned <b>{mention.mentioned?.fullName || 'User'}</b> in the patient's internal chat (MRN&nbsp;{mention.patientId})
          </Typography>
          {mention.createdAt && (
            <Typography color="text.secondary" variant="caption" data-testid={`${testId}-date`}>
              {dayjs(mention.createdAt).format('MMM D, hh:mm A')}
            </Typography>
          )}
        </div>
      )}
    </ListItemButton>
  )
}

MentionItem.Loading = function ({ divider }) {
  return (
    <ListItemButton divider={divider}>
      <Box sx={{ width: '100%' }}>
        <Typography variant="body2">
          <Skeleton />
          <Skeleton width={150} />
        </Typography>
        <Typography color="text.secondary" variant="caption">
          <Skeleton width={100} />
        </Typography>
      </Box>
    </ListItemButton>
  )
}
