import { useVoiceWidgetState } from '@providers/VoiceWidgetStateProvider'
import { PhoneOutlinedIcon } from '@icons'
import { Box, IconButton, useMediaQuery } from '@mui-components'

export default function PhoneCall() {
  const { toggle } = useVoiceWidgetState()
  const matchDownSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <Box sx={{ flexShrink: 0, ml: 0.5 }}>
      <IconButton
        id="phone-call-button"
        color="secondary"
        onClick={() => toggle()}
        aria-label="Phone Call"
        data-testid="header-phone-call"
        size={matchDownSm ? 'small' : 'medium'}
        sx={{ color: 'text.primary' }}
      >
        <PhoneOutlinedIcon style={{ fontSize: 23 }} />
      </IconButton>
    </Box>
  )
}
