import { createContext, useContext } from 'react'

/**
 * @typedef {Object} HideDetailsContextType
 * @property {string} [Avatars] - Type representing avatars detail.
 */

/**
 * Create a context for hiding details.
 *
 * The context provides a way to pass down the hide details state
 * without having to pass props through every level of the component tree.
 *
 * @type {React.Context<HideDetailsContextType>}
 */
const HideDetailsContext = createContext({})

/**
 * Props for the HideDetailsProvider component.
 *
 * @typedef {Object} HideDetailsProviderProps
 * @property {HideDetailsContextType} value - The value to be provided to consuming components.
 * @property {React.ReactNode} children - The child components that will have access to the context.
 */

/**
 * Provider component for HideDetailsContext.
 *
 * This component wraps its children with a context provider,
 * allowing them to access the hide details state.
 *
 * @param {HideDetailsProviderProps} props - The props for the provider.
 * @param {HideDetailsContextType} props.value - The value to provide to the context.
 * @param {React.ReactNode} props.children - The child elements that can consume the context.
 * @returns {JSX.Element} The context provider wrapping the children.
 */
export default function HideDetails({ value, children }) {
  return <HideDetailsContext.Provider value={value}>{children}</HideDetailsContext.Provider>
}

/**
 * Custom hook to consume the HideDetailsContext.
 *
 * This hook provides easy access to the hide details state
 * from any component within the HideDetails.
 *
 * @returns {HideDetailsContextType} The current context value.
 */
export const useHideDetails = () => useContext(HideDetailsContext)

/**
 * Enumeration of detail types that can be hidden.
 *
 * @enum {string}
 */
export const HideDetailsType = {
  /** Represents avatar details */
  Avatars: 'avatars',
}
