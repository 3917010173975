import useQuery from '@shared/hooks/src/useQuery'
import UserToursApi, { UserTourKeys } from '@shared/services/src/UserTours.api'

export default function useTours(userId) {
  return useQuery({
    queryKey: UserTourKeys.list(userId),
    queryFn: () => UserToursApi.tours(userId, { expired: false }),
  })
}

export function useTour(userId, key, options = {}) {
  return useQuery({
    queryKey: UserTourKeys.tour(userId, key),
    queryFn: () => UserToursApi.tour(userId, key),
    ...options,
  })
}

export function useIsTourInProgress(userId, key, options = {}) {
  const { data } = useTour(userId, key, {
    select: (tour) => tour && tour.active && !tour.finished,
    ...options,
  })

  return data
}
