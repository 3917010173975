import dayjs from 'dayjs'
import kebabCase from 'lodash/kebabCase'

import { getFriendlyDuration, TimeSlotDuration } from '@shared/utils'

export const format = 'YYYY-MM-DD HH:mm'
export const slotDuration = `00:${TimeSlotDuration}:00`
export const Mode = {
  Availability: 'availability',
  Appointment: 'appointment',
}
export const ScheduleType = {
  All: 'all',
  Scheduled: 'scheduled',
}
export const CalendarEvent = {
  Availability: 'availability',
  AdminTime: 'adminTime',
  Appointment: 'appointment',
  AdHocAppointment: 'adHocAppointment',
  Blackout: 'blackout',
}
export const PastEntriesErrorString = 'Sorry, modifications to past entries are not allowed'

export const stringifyDuration = (duration) => {
  const hours = Math.floor(duration / 60)
  const minutes = duration % 60

  if (hours && minutes) return `${hours}h ${minutes}m`
  if (hours) return `${hours}h`
  return `${minutes}m`
}

const createAvailabilityEvent = (item, timezone) => {
  const start = dayjs(item.start).tz(timezone)
  const end = dayjs(item.end).tz(timezone)
  const duration = end.diff(start, 'minutes')

  return {
    start: start.format(format),
    end: end.format(format),
    duration,
    display: 'background',
    className: 'availability-slot',
    extendedProps: { id: item.id, type: CalendarEvent.Availability },
  }
}

const createAdminTimeEvent = (item, timezone) => {
  const start = dayjs(item.start).tz(timezone)
  const end = dayjs(item.end).tz(timezone)
  const duration = end.diff(start, 'minutes')

  return {
    start: start.format(format),
    end: end.format(format),
    duration,
    display: 'background',
    className: 'admin-time-slot',
    extendedProps: { id: item.id, type: CalendarEvent.AdminTime },
  }
}

const createAppointmentEvent = (item, timezone) => {
  const start = dayjs(item.start).tz(timezone)
  const end = item.end ? dayjs(item.end).tz(timezone) : start.add(item.duration || TimeSlotDuration, 'minutes')
  const time = start.format('LT')
  const name = item.user.fullName

  return {
    start: start.format(format),
    end: end.format(format),
    title: `${time} ${name}`,
    className: ['appointment-slot', kebabCase(item.status)],
    extendedProps: {
      id: item.id,
      type: CalendarEvent.Appointment,
      tooltip: `${time}  ${getFriendlyDuration(start, end)} - ${name} - ${item.status}`,
    },
  }
}

const createAdHocAppointmentEvent = (item, timezone) => {
  const start = dayjs(item.start).tz(timezone)
  const end = start.add(item.duration || TimeSlotDuration, 'minutes')
  const time = start.format('LT')
  const name = 'Ad Hoc'

  return {
    start: start.format(format),
    end: end.format(format),
    title: `${time} ${name}`,
    className: 'ad-hoc-appointment-slot',
    extendedProps: {
      id: item.id,
      type: CalendarEvent.AdHocAppointment,
      tooltip: `${time}  ${getFriendlyDuration(start, end)} - ${name}`,
    },
  }
}

const createBlackoutEvent = (item, timezone, additionalProps) => {
  let startBlackout, endBlackout, titleBlackout, extendedPropsBlackout

  if (item.allDay) {
    const dateBlackout = dayjs(item.allDayDate)
    startBlackout = `${dateBlackout.format('YYYY-MM-DD')} ${additionalProps.businessHours.start_time}`
    endBlackout = `${dateBlackout.format('YYYY-MM-DD')} ${additionalProps.businessHours.end_time}`
    titleBlackout = item.title
    extendedPropsBlackout = {
      tooltip: `${dateBlackout.format('L')} - ${item.title}`,
    }
  } else {
    const startBlackoutDT = dayjs(item.start).tz(timezone)
    const endBlackoutDT = dayjs(item.end).tz(timezone)
    startBlackout = startBlackoutDT.format(format)
    endBlackout = endBlackoutDT.format(format)
    titleBlackout = `${startBlackoutDT.format('LT')} ${item.title}`
    extendedPropsBlackout = {
      tooltip: `${startBlackoutDT.format('LT')} - ${endBlackoutDT.format('LT')} ${item.title}`,
    }
  }

  return {
    className: 'blackout-slot',
    title: titleBlackout,
    start: startBlackout,
    end: endBlackout,
    extendedProps: extendedPropsBlackout,
  }
}

const typeToFunctionMap = {
  [CalendarEvent.Availability]: createAvailabilityEvent,
  [CalendarEvent.AdminTime]: createAdminTimeEvent,
  [CalendarEvent.Appointment]: createAppointmentEvent,
  [CalendarEvent.AdHocAppointment]: createAdHocAppointmentEvent,
  [CalendarEvent.Blackout]: createBlackoutEvent,
}

export const eventsFactory = (data = [], type, timezone, additionalProps = {}) => {
  const createEventFunction = typeToFunctionMap[type]
  if (!createEventFunction) return []
  return data.map((item) => createEventFunction(item, timezone, additionalProps))
}

// TODO optimize using right data structure
export const findAvailability = ({ start, end }, availabilities = []) => {
  const selectStart = dayjs(start)
  const selectEnd = dayjs(end)

  return availabilities.find((avail) => {
    const availStart = dayjs(avail.start)
    const availEnd = dayjs(avail.end)

    return selectStart.isSameOrAfter(availStart) && selectEnd.isSameOrBefore(availEnd)
  })
}
