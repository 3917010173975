import React from 'react'
import dayjs from 'dayjs'
import { useFormik } from 'formik'

import usePromiseLoading from '@shared/hooks/src/usePromiseLoading'
import { getErrorDetails, handleError } from '@shared/utils'

import { MoreOutlinedIcon } from '@icons'
import { Box, Button, Divider, LoadingButton, Popover, Stack } from '@mui-components'
import Section from '@components/Details/Section'
import LinearProgress from '@components/LinearProgress'
import TimelineNotes, { TimelineNoteCreation } from '@components/TimelineNotes'

import { validationSchema } from '../../AvailableItems'
import { useAdHocAppointmentCreate, useAdHocAppointmentEdit, useAdHocAppointmentRemove } from '../../Edit/Edit.hooks'
import { ActionButtons, TimeRangePicker } from '../AvailabilityForm/AvailabilityForm.utils'
import { findAvailability } from '../Calendar.utils'
import { useAdHocAppointmentNoteCreation, useAdHocAppointmentNotes } from './AppointmentForm.hooks'
import { AppointmentToggleGroup } from './AppointmentForm.utils'

export default function AppointmentUpdate({
  readOnly = false,
  providerId,
  timezone,
  availabilities,
  appointment,
  open,
  onClose,
  anchorPosition,
}) {
  return (
    <Popover
      id={open ? 'appointment-popover' : undefined}
      open={open}
      anchorReference={anchorPosition ? 'anchorPosition' : 'none'}
      anchorPosition={anchorPosition}
      disableAutoFocus
      disableRestoreFocus
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      slotProps={{ paper: { sx: { width: 420, overflow: 'hidden' } } }}
    >
      <AppointmentForm
        readOnly={readOnly}
        providerId={providerId}
        timezone={timezone}
        availabilities={availabilities}
        appointment={appointment}
        onClose={onClose}
      />
    </Popover>
  )
}

function AppointmentForm({ readOnly, providerId, timezone, availabilities, appointment, onClose }) {
  const createAdHocAppointment = useAdHocAppointmentCreate(providerId)
  const editAdHocAppointment = useAdHocAppointmentEdit(providerId)
  const removeAdHocAppointment = useAdHocAppointmentRemove(providerId)

  const isInThePast = dayjs(appointment.end).isBefore(dayjs().tz(timezone))
  const isEditingExistingAppointment = Boolean(appointment?.id)

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      id: appointment?.id || undefined,
      start: appointment?.start ? dayjs(appointment.start) : null,
      end: appointment?.end ? dayjs(appointment.end) : null,
    },
    validationSchema,
    onSubmit: ({ id, start, end }, { setFieldError }) => {
      const mutation = isEditingExistingAppointment ? editAdHocAppointment : createAdHocAppointment

      const availability = findAvailability({ start, end }, availabilities)
      if (!availability) return Promise.resolve().then(() => setFieldError('range', 'Can be placed only within availability'))

      const data = {
        id,
        availability_id: availability.extendedProps.id,
        time: start.tz(timezone, true).format(),
        duration: end.diff(start, 'minutes'),
      }

      return mutation(data)
        .then(onClose)
        .catch((e) => setFieldError('range', getErrorDetails(e).join(', ')))
    },
  })

  const [remove, removing] = usePromiseLoading(() => {
    return removeAdHocAppointment(appointment.id)
      .then(onClose)
      .catch((e) => handleError(e, { showResponse: true }))
  })

  const isLoading = formik.isSubmitting || removing

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <LinearProgress loading={isLoading} />

      <Stack p={2} spacing={2}>
        <AppointmentToggleGroup value={'ad-hoc'} onChange={() => undefined} disabled={isEditingExistingAppointment} />

        <TimeRangePicker
          readOnly={readOnly}
          disabled={isInThePast}
          start={formik.values.start}
          end={formik.values.end}
          onStartChange={(start) => formik.setFieldValue('start', start)}
          onEndChange={(end) => formik.setFieldValue('end', end)}
          errors={formik.errors}
          touched={formik.touched}
          isLoading={isLoading}
        />

        {readOnly ? (
          <Button onClick={onClose} color="primary" variant="outlined">
            Close
          </Button>
        ) : (
          <ActionButtons
            disabled={isInThePast}
            isLoading={isLoading}
            isEditing={isEditingExistingAppointment}
            onCancel={onClose}
            isRemoving={removing}
            onDelete={remove}
          />
        )}

        {isEditingExistingAppointment && <Divider />}
      </Stack>

      {isEditingExistingAppointment && <AdHocNotes providerId={providerId} appointmentId={appointment.id} readOnly={readOnly} />}
    </form>
  )
}

function AdHocNotes({ providerId, appointmentId, readOnly }) {
  const create = useAdHocAppointmentNoteCreation(providerId, appointmentId)
  const { data, isPending, fetchNextPage, hasNextPage, isFetchingNextPage } = useAdHocAppointmentNotes(providerId, appointmentId)

  const showLoading = isPending
  const showEmpty = !showLoading && data?.length === 0
  const showData = !showEmpty && data?.length > 0

  return (
    <Box sx={{ maxHeight: 400, overflowY: 'scroll', mt: -2, p: 2 }}>
      <Section mini title="Notes">
        {!readOnly && (
          <TimelineNoteCreation draftKey={`appointment-${appointmentId}-note`} onSend={(message) => create.mutateAsync(message)} />
        )}
        {showLoading && <TimelineNotes.Loading />}
        {showEmpty && <TimelineNotes.Empty readOnly={readOnly} />}
        {showData && (
          <TimelineNotes>
            {data.map((note, i) => {
              return <TimelineNotes.Item key={note.id} data={note} isLast={data.length - 1 === i} />
            })}
            {hasNextPage && (
              <LoadingButton
                fullWidth
                loading={isFetchingNextPage}
                onClick={() => fetchNextPage()}
                endIcon={<MoreOutlinedIcon rotate={90} />}
                loadingPosition="end"
                sx={{ textTransform: 'none' }}
              >
                more
              </LoadingButton>
            )}
          </TimelineNotes>
        )}
      </Section>
    </Box>
  )
}
