import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'

import usePagesHistory, { PageHistoryType } from '@hooks/usePagesHistory'
import { CaretDownOutlinedIcon, CaretUpOutlinedIcon, ExperimentOutlinedIcon, MedicineBoxOutlinedIcon, PillIcon } from '@icons'
import {
  Avatar as AvatarMui,
  Box,
  Button,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  Typography,
} from '@mui-components'
import Avatar from '@components/Avatar'
import { getSizeStyle } from '@components/Avatar/Avatar.utils'

export default function PagesHistoryPopover({ anchorEl, open = false, onClose }) {
  const { history, clear } = usePagesHistory()

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      open={open}
      onClose={onClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      slotProps={{
        paper: {
          sx: { width: '400px' },
          'data-testid': 'page-history-paper',
        },
      }}
    >
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-between', px: 3, py: 2 }}>
        <Typography variant="h4">History</Typography>
        <Button onClick={clear} size="small" sx={{ textTransform: 'none' }} data-testid="page-history-mark-clear">
          Clear
        </Button>
      </Stack>
      {history.length === 0 ? (
        <Box sx={{ p: 2 }} data-testid="page-history-empty">
          <Typography variant="h5" color="text.secondary" sx={{ height: 50, fontWeight: 'normal', textAlign: 'center' }}>
            Nothing to see here!
          </Typography>
        </Box>
      ) : (
        <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
          <List disablePadding data-testid="page-history-list">
            {history.map((item, index) => (
              <HistoryItem key={item.id} divider={index < history.length - 1} item={item} onClose={onClose} />
            ))}
          </List>
        </Box>
      )}
    </Popover>
  )
}

function HistoryItem({ item, patient, divider = false, onClose }) {
  const navigate = useNavigate()
  const [expanded, setExpanded] = useState()

  if (item.type === PageHistoryType.Patient) {
    const testId = `page-history-patient-item-${item.data.fullName}`

    const children = item.children || []
    return (
      <>
        <ListItemButton
          divider={divider}
          data-testid={testId}
          onClick={() => {
            navigate(`/app/patients/${item.data.id}`)
            onClose()
          }}
        >
          <ListItemIcon>
            <Avatar user={item.data} size="lg" />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography noWrap data-testid={`${testId}-name`} sx={{ lineHeight: 1 }}>
                {item.data.fullName}
              </Typography>
            }
            secondary={
              <Stack spacing={-0.5}>
                <Typography component="span" color="text.secondary" variant="body2" data-testid={`${testId}-id`}>
                  Patient {item.data.id}
                </Typography>
                <Typography color="text.secondary" variant="caption" data-testid={`${testId}-date`}>
                  Last Opened: {dayjs(item.createdAt).format('MMM D, hh:mm A')}
                </Typography>
              </Stack>
            }
            sx={{ pl: 1, my: 0 }}
          />
          {children.length > 0 && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                setExpanded((e) => !e)
              }}
            >
              {expanded ? <CaretUpOutlinedIcon /> : <CaretDownOutlinedIcon />}
            </IconButton>
          )}
        </ListItemButton>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List disablePadding data-testid={`${testId}-children`}>
            {children.map((subitem) => (
              <HistoryItem key={subitem.id} item={subitem} patient={item} onClose={onClose} />
            ))}
          </List>
        </Collapse>
      </>
    )
  }

  if (item.type === PageHistoryType.Lab) {
    const testId = `page-history-lab-item-${item.data.id}`

    return (
      <ListItemButton
        divider={divider}
        data-testid={testId}
        onClick={() => {
          navigate(`/app/labs/${item.data.id}`)
          onClose()
        }}
      >
        <ListItemIcon>
          <AvatarMui sx={avatarStyles}>
            <ExperimentOutlinedIcon />
          </AvatarMui>
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <Typography noWrap data-testid={`${testId}-name`} sx={{ lineHeight: 1 }}>
              {item.data.lab} Lab
            </Typography>
          }
          secondary={
            <Stack spacing={-0.5}>
              <Typography component="span" color="text.secondary" variant="body2" data-testid={`${testId}-id`}>
                ID: {item.data.id} - {item.data.status}
              </Typography>
              <Typography color="text.secondary" variant="caption" data-testid={`${testId}-date`}>
                Last Opened: {dayjs(item.createdAt).format('MMM D, hh:mm A')}
              </Typography>
            </Stack>
          }
          sx={{ pl: 1, my: 0 }}
        />
      </ListItemButton>
    )
  }

  if (item.type === PageHistoryType.Rx) {
    const testId = `page-history-rx-item-${item.data.id}`

    return (
      <ListItemButton
        divider={divider}
        data-testid={testId}
        onClick={() => {
          navigate(`/app/prescriptions/${item.data.id}`)
          onClose()
        }}
      >
        <ListItemIcon>
          <AvatarMui sx={avatarStyles}>
            <PillIcon />
          </AvatarMui>
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <Typography noWrap data-testid={`${testId}-name`} sx={{ lineHeight: 1 }}>
              {item.data.drugName}
            </Typography>
          }
          secondary={
            <Stack spacing={-0.5}>
              <Typography component="span" color="text.secondary" variant="body2" data-testid={`${testId}-id`}>
                ID: {item.data.id} - {item.data.rxStatus}
              </Typography>
              <Typography color="text.secondary" variant="caption" data-testid={`${testId}-date`}>
                Last Opened: {dayjs(item.createdAt).format('MMM D, hh:mm A')}
              </Typography>
            </Stack>
          }
          sx={{ pl: 1, my: 0 }}
        />
      </ListItemButton>
    )
  }

  if (item.type === PageHistoryType.Encounter) {
    const testId = `page-history-encounter-item-${item.data.id}`

    return (
      <ListItemButton
        divider={divider}
        data-testid={testId}
        onClick={() => {
          navigate(`/app/patients/${patient.data.id}/encounters/${item.data.id}`)
          onClose()
        }}
      >
        <ListItemIcon>
          <AvatarMui sx={avatarStyles}>
            <MedicineBoxOutlinedIcon />
          </AvatarMui>
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <Typography noWrap data-testid={`${testId}-name`} sx={{ lineHeight: 1 }}>
              {item.data.description}
            </Typography>
          }
          secondary={
            <Stack spacing={-0.5}>
              <Typography component="span" color="text.secondary" variant="body2" data-testid={`${testId}-id`}>
                ID: {item.data.id}
              </Typography>
              <Typography color="text.secondary" variant="caption" data-testid={`${testId}-date`}>
                Last Opened: {dayjs(item.createdAt).format('MMM D, hh:mm A')}
              </Typography>
            </Stack>
          }
          sx={{ pl: 1, my: 0 }}
        />
      </ListItemButton>
    )
  }

  return null
}

const avatarStyles = [getSizeStyle('lg'), { backgroundColor: 'background.paper', color: 'text.primary' }]
