import React, { useState } from 'react'
import { Annotation, ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps'
import { geoCentroid } from 'd3-geo'
import { useTheme } from '@mui/material/styles'

import statesMap from './states-map.json'
import { statesObject } from './USAMap.utils'

const offsets = {
  RI: [28, 2],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21],
  // may be needed:
  // VT: [50, -8],
  // NH: [34, 2],
  // MA: [30, -1],
  // CT: [35, 10],
}

/**
 * US states map component
 *
 * @param data {Object} - data object
 * @param data.fill {String} - fill color
 * @param defaultFill {String} - default fill color
 * @param onClick {Function} - click handler
 * @param Tooltip {React.Component} - tooltip component
 */
export default function USAMap({ data, defaultFill = '#d9d9d9', onClick, Tooltip }) {
  const theme = useTheme()
  const [activeGeo, setActiveGeo] = useState('')

  return (
    <ComposableMap projection="geoAlbersUsa">
      <Geographies geography={statesMap}>
        {({ geographies }) => (
          <>
            {geographies.map((geo) => {
              const abbreviation = statesObject[geo.id]
              const isHovered = geo.id === activeGeo

              return (
                <Tooltip key={geo.rsmKey} abbreviation={abbreviation}>
                  <Geography
                    geography={geo}
                    onMouseEnter={() => setActiveGeo(geo.id)}
                    onMouseLeave={() => setActiveGeo(null)}
                    fill={data[abbreviation]?.fill || defaultFill}
                    fillOpacity={isHovered ? 1 : 0.8}
                    stroke="#FFFFFF"
                    onClick={onClick ? () => onClick(abbreviation) : undefined}
                    style={{
                      default: { outline: 'none' },
                      hover: { outline: 'none', cursor: 'pointer' },
                      pressed: { outline: 'none', cursor: 'pointer' },
                    }}
                  />
                </Tooltip>
              )
            })}

            {geographies.map((geo) => {
              const centroid = geoCentroid(geo)
              const abbreviation = statesObject[geo.id]
              const noOffset = !offsets[abbreviation]

              return (
                <g key={geo.rsmKey + '-name'}>
                  {abbreviation &&
                    centroid[0] > -160 &&
                    centroid[0] < -67 &&
                    (noOffset ? (
                      <Tooltip key={geo.rsmKey} abbreviation={abbreviation}>
                        <Marker coordinates={centroid} onClick={onClick ? () => onClick(abbreviation) : undefined}>
                          <text
                            y="2"
                            fontSize={10}
                            textAnchor="middle"
                            onMouseEnter={() => setActiveGeo(geo.id)}
                            onMouseLeave={() => setActiveGeo(null)}
                            style={{ cursor: 'pointer' }}
                            fill={theme.palette.text.primary}
                          >
                            {abbreviation}
                          </text>
                        </Marker>
                      </Tooltip>
                    ) : (
                      <Tooltip key={geo.rsmKey} abbreviation={abbreviation}>
                        <Annotation
                          subject={centroid}
                          dx={offsets[abbreviation][0]}
                          dy={offsets[abbreviation][1]}
                          onClick={onClick ? () => onClick(abbreviation) : undefined}
                        >
                          <text
                            x={4}
                            fontSize={10}
                            alignmentBaseline="middle"
                            onMouseEnter={() => setActiveGeo(geo.id)}
                            onMouseLeave={() => setActiveGeo(null)}
                            style={{ cursor: 'pointer' }}
                          >
                            {abbreviation}
                          </text>
                        </Annotation>
                      </Tooltip>
                    ))}
                </g>
              )
            })}
          </>
        )}
      </Geographies>
    </ComposableMap>
  )
}
