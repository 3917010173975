import { useCallback, useEffect, useState } from 'react'

import { convertJsonToPlainText } from '@shared/utils'

import { useAllQPhrases } from '@providers/QPhrasesProvider'
import useQPhraseUsage from '@hooks/useQPhrasesUsage'
import { List, ListItemButton, ListItemText, Popover, Stack, Typography } from '@mui-components'

export default function QPhrasesInputHandler() {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const { activeElement, query, showPopup, setShowPopup } = useActiveInput()

  const phrases = useAllQPhrases(query)
  const { increment } = useQPhraseUsage()

  useEffect(() => {
    if (phrases?.length - 1 >= selectedIndex) return
    setSelectedIndex(0)
  }, [phrases?.length, query, selectedIndex])

  const insertPhrase = useCallback(
    (phrase) => {
      const element = document.activeElement

      const text = element.value
      const cursorPosition = element.selectionStart
      const words = text.slice(0, cursorPosition).split(/\s+/)
      const lastWord = words[words.length - 1]

      const value = convertJsonToPlainText(JSON.parse(phrase.value))

      element.value = text.slice(0, cursorPosition - lastWord.length) + value + text.slice(cursorPosition)
      const newCursorPosition = cursorPosition - lastWord.length + value.length
      element.setSelectionRange(newCursorPosition, newCursorPosition)

      setSelectedIndex(0)

      increment(phrase.id)
    },
    [increment]
  )

  const handleKeyDown = useCallback(
    (event) => {
      if (!showPopup || phrases.length === 0) return

      if (event.key === 'ArrowUp') {
        event.preventDefault()
        setSelectedIndex((selectedIndex) => (selectedIndex + phrases.length - 1) % phrases.length)
      }

      if (event.key === 'ArrowDown') {
        event.preventDefault()
        setSelectedIndex((selectedIndex) => (selectedIndex + 1) % phrases.length)
      }

      if (event.key === ' ') {
        insertPhrase(phrases[selectedIndex])
      }

      if (event.key === 'Escape') {
        setShowPopup(false)
      }
    },
    [phrases, selectedIndex, showPopup, insertPhrase, setShowPopup]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  return (
    <Popover
      open={showPopup && phrases?.length > 0}
      anchorEl={activeElement}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      disableAutoFocus
    >
      <Stack sx={{ width: '100%', maxWidth: 400, alignItems: 'center' }}>
        <List dense disablePadding sx={{ width: '100%' }}>
          {phrases.map((item, index) => (
            <ListItemButton key={index} divider selected={selectedIndex === index}>
              <ListItemText primary={item.title} secondary={item.abbreviation} />
            </ListItemButton>
          ))}
        </List>
        <Typography variant="body2" color="text.secondary" align="center" sx={{ maxWidth: 240, p: 1 }}>
          Use the arrow keys to select a <b>Q Phrase</b>, then press <b>Space</b> to insert it or <b>Esc</b> to cancel.
        </Typography>
      </Stack>
    </Popover>
  )
}

const useActiveInput = () => {
  const [activeElement, setActiveElement] = useState(null)
  const [showPopup, setShowPopup] = useState(false)
  const [query, setQuery] = useState('')

  useEffect(() => {
    const handleSelectionChange = () => {
      const element = document.activeElement

      if (element && (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA')) {
        const value = element.value
        const cursorPosition = element.selectionStart

        if (cursorPosition !== null) {
          // Slice the value up to the cursor position and split by word boundaries (spaces)
          const wordsBeforeCursor = value.slice(0, cursorPosition).split(/\s+/)
          const lastWord = wordsBeforeCursor[wordsBeforeCursor.length - 1]

          // Check if the last word starts with '/'
          if (lastWord.startsWith('/')) {
            setShowPopup(true)
            setQuery(lastWord)
          } else {
            setShowPopup(false)
            setQuery('')
          }

          // Update the active element
          setActiveElement(element)
        } else {
          setShowPopup(false)
          setQuery('')
          setActiveElement(null)
        }
      } else {
        setShowPopup(false)
        setQuery('')
        setActiveElement(null)
      }
    }

    document.addEventListener('selectionchange', handleSelectionChange)

    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange)
    }
  }, [])

  return { activeElement, showPopup, setShowPopup, query: query.slice(1) }
}
