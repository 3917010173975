// ==============================|| OVERRIDES - DIALOG TITLE ||============================== //

export default function DialogTitle(theme) {
  return {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 700,
          [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
          },
        },
      },
    },
  }
}
