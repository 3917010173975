import { useMemo } from 'react'
import dayjs from 'dayjs'
import { useTheme } from '@mui/material/styles'

import useLoadingState from '@shared/hooks/src/useLoadingState'
import { useMe } from '@shared/providers/src/MeProvider'

import useAvailability from '@hooks/useAvailability'
import useBlackoutPeriods from '@hooks/useBlackoutPeriods'
import Availability from '@pages/Availability'
import Calendar from '@pages/Availability/Calendar'
import { CalendarOutlinedIcon, CloseCircleOutlinedIcon, CloseOutlinedIcon } from '@icons'
import { AppBar, Box, Button, Container, Dialog, Drawer, IconButton, Stack, Toolbar, Typography, useMediaQuery } from '@mui-components'
import AvailabilityDay from '@components/AvailabilityDay'
import DocTitle from '@components/DocTitle'
import LinearProgress from '@components/LinearProgress'
import MainCard from '@components/MainCard'

import { useAvailabilityDrawer } from './Availability.hooks'

const styles = {
  card: {
    border: 'none',
    borderRadius: 0,
    height: '100vh',
    '& .MuiCardHeader-root': {
      color: 'background.paper',
      bgcolor: 'primary.main',
      '& .MuiTypography-root': { fontSize: '1rem' },
    },
  },
}

export default function AvailabilityButton() {
  const [, updateDrawer] = useAvailabilityDrawer()
  const matchDownSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 0.5 }}>
        <IconButton
          id="availability"
          color="secondary"
          onClick={() => updateDrawer('preview')}
          aria-label="settings toggler"
          data-testid="header-calendar"
          size={matchDownSm ? 'small' : 'medium'}
          sx={{ color: 'text.primary' }}
        >
          <CalendarOutlinedIcon style={{ fontSize: 21 }} />
        </IconButton>
      </Box>

      <AvailabilityPreviewDrawer />
      <UpdateAvailabilityDialog />
      <MyCalendarDialog />
    </>
  )
}

function AvailabilityPreviewDrawer() {
  const [drawer, updateDrawer] = useAvailabilityDrawer()

  const open = drawer === 'preview'
  const handleClose = () => updateDrawer(undefined)

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor="right"
      sx={{ zIndex: 2001 }}
      PaperProps={{ sx: { width: 340 } }}
      data-testid="drawer-availability"
    >
      <AvailabilityPreview onCalendar={() => updateDrawer('calendar')} onClose={handleClose} />
    </Drawer>
  )
}

function AvailabilityPreview({ onCalendar, onClose }) {
  const period = useMemo(() => {
    return {
      startDate: dayjs().tz(window.timezone).startOf('day'),
      endDate: dayjs().tz(window.timezone).add(6, 'days').endOf('day'),
    }
  }, [])

  const {
    data: availabilityData,
    isPending: isAvailabilityPending,
    isFetching: isAvailabilityFetching,
    isRefreshing: isAvailabilityRefreshing,
  } = useAvailability(period)

  const {
    data: blackoutsData,
    isPending: areBlackoutsPending,
    isFetching: areBlackoutsFetching,
    isRefreshing: areBlackoutsRefreshing,
  } = useBlackoutPeriods(period)

  const data = useMemo(() => {
    return getDates(0, 7).map((date) => {
      const day = date.format('YYYY-MM-DD')

      const availability = availabilityData?.[day]
      const blackouts = blackoutsData?.[day]

      return { date, availability, blackouts }
    })
  }, [availabilityData, blackoutsData])

  const isLoading = useLoadingState(isAvailabilityPending || areBlackoutsPending)
  const isRefreshing = useLoadingState(isAvailabilityRefreshing || areBlackoutsRefreshing || isAvailabilityFetching || areBlackoutsFetching)

  return (
    <MainCard
      title="Upcoming Availability"
      secondary={
        <IconButton shape="rounded" color="inherit" size="small" onClick={onClose} data-testid="close-drawer">
          <CloseCircleOutlinedIcon style={{ fontSize: '1.15rem' }} />
        </IconButton>
      }
      sx={styles.card}
      content={false}
      data-testid="availability-drawer"
    >
      <LinearProgress loading={isRefreshing} color="warning" />

      <Stack spacing={2} sx={{ p: 2, height: '100%', overflowY: 'auto' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button fullWidth variant="contained" data-testid="view-my-calendar-button" onClick={onCalendar} id="view-my-calendar-button">
            View My Calendar
          </Button>
        </Box>

        {data.map(({ date, availability, blackouts }, i) => (
          <AvailabilityDay key={date} date={date} isLoading={isLoading} availability={availability} blackouts={blackouts} />
        ))}
      </Stack>
    </MainCard>
  )
}

function UpdateAvailabilityDialog() {
  const theme = useTheme()
  const [drawer, updateDrawer] = useAvailabilityDrawer()

  const open = drawer === 'update'
  const handleClose = () => updateDrawer('calendar')

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { backgroundColor: 'background.default' } }}
      TransitionProps={getTransition(theme)}
    >
      <DocTitle title="Appointment Availability" />
      <AppBar>
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h4">
            Appointment Availability
          </Typography>
          <IconButton shape="rounded" color="inherit" onClick={handleClose} data-testid="close-availability-update">
            <CloseOutlinedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: (theme) => `${theme.mixins.toolbar.height}px` }}>
        <Availability />
      </Container>
    </Dialog>
  )
}

function MyCalendarDialog() {
  const me = useMe()
  const theme = useTheme()
  const [drawer, updateDrawer] = useAvailabilityDrawer()

  const open = drawer === 'calendar'
  const handleClose = () => updateDrawer(undefined)

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { backgroundColor: 'background.default' } }}
      TransitionProps={getTransition(theme)}
    >
      <DocTitle title="Calendar" />
      <AppBar>
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h4">
            Calendar
          </Typography>
          <IconButton shape="rounded" color="inherit" onClick={handleClose} data-testid="close-calendar">
            <CloseOutlinedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: (theme) => `${theme.mixins.toolbar.height}px` }}>
        <Box sx={{ pb: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" onClick={() => updateDrawer('update')} id="update-availability-button">
            Update Appointment Availability
          </Button>
        </Box>
        <Calendar user={me} />
      </Container>
    </Dialog>
  )
}

const getTransition = (theme) => ({
  timeout: {
    enter: 0,
    appear: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  },
})

function getDates(start, end) {
  const dates = []
  for (let i = start; i < end; i++) {
    dates.push(dayjs().tz(window.timezone).add(i, 'day'))
  }
  return dates
}
