import { createContext, useContext, useMemo } from 'react'

import useQPhraseUsage from '@hooks/useQPhrasesUsage'

import { useAllQPhrasesQuery } from './QPhrasesProvider.hooks'

/**
 * Provide a context for all the data of QPhrases
 */
export default function QPhrasesProvider({ children }) {
  const query = useAllQPhrasesQuery()

  return <QPhrasesContext.Provider value={query}>{children}</QPhrasesContext.Provider>
}

const QPhrasesContext = createContext({ data: [] })

export const useAllQPhrases = (search) => {
  const { data } = useContext(QPhrasesContext)

  const { stats } = useQPhraseUsage()

  return useMemo(() => {
    return data
      .filter((phrase) => phrase.abbreviation.startsWith(search))
      .sort((a, b) => (stats[b.id] || 0) - (stats[a.id] || 0))
      .slice(0, 5)
  }, [data, stats, search])
}
