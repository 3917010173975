import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'

export const QPhrasesKeys = {
  // ['q-phrases']
  all: ['q-phrases'],

  // ['q-phrases', 'lists']
  lists: () => [...QPhrasesKeys.all, 'lists'],

  // ['q-phrases', 'lists', { ...query }]
  list: (query) => [...QPhrasesKeys.lists(), query],
}

const QPhrasesApi = {
  list: (query) =>
    api
      .get(QS.stringifyUrl({ url: `/snippets`, query }, { arrayFormat: 'bracket' }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  create: (data, config) =>
    api.post('/snippets', data, config).then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),

  update: (id, data, config) =>
    api.patch(`/snippets/${id}`, data, config).then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),

  setStatus: (id, data) =>
    api.patch(`/snippets/${id}/set_status`, data).then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),
}

export default QPhrasesApi
