import { useMemo } from 'react'

import { useIsTourInProgress } from '@shared/hooks/src/useTours'
import { useMe } from '@shared/providers/src/MeProvider'

import { Stack, Typography } from '@mui-components'
import { TourBasic } from '@components/Tour'
import step3file from '@assets/calendarTour/step3.mp4'

import { ColorScheme } from './Calendar.components'

const tourKey = 'calendar-nov-updates-tour'

export default function CalendarUpdatesTour() {
  const me = useMe()
  const inProgress = useIsTourInProgress(me.id, tourKey)

  const steps = useMemo(() => {
    return [step1, step2, step3, step4, step5]
  }, [])

  if (inProgress) {
    return <TourBasic tourKey={tourKey} steps={steps} />
  }

  return null
}

const stepFabric = ({ title, content, sx }) => ({
  target: '#root',
  disableBeacon: true,
  placement: 'center',
  title,
  content,
  sx,
  styles: {
    options: {
      zIndex: 1301,
    },
  },
})

const step1 = stepFabric({
  title: 'Welcome to the Updated Calendar!',
  content: (
    <Typography>
      Discover our enhanced color schemes, new appointment types, and more exciting features designed to streamline your scheduling
      experience.
    </Typography>
  ),
})

const step2 = stepFabric({
  title: 'Enhanced Color Schemes',
  content: (
    <Stack
      color="text.contrast"
      sx={{
        color: 'text.contrast',
        p: 2,
        gap: 2,
        borderRadius: 2,
        backgroundColor: 'grey.0',
      }}
    >
      <ColorScheme />
      <Typography>
        Our new color palettes make it easier to differentiate between various appointment types and statuses at a glance.
      </Typography>
    </Stack>
  ),
})

const step3 = stepFabric({
  title: 'Manage Your Appointments Effortlessly',
  content: <Step3Content />,
  sx: {
    width: { xs: 330, sm: 700, md: 900 },
  },
})

function Step3Content() {
  return (
    <Stack spacing={2}>
      <Typography>Select the desired mode to seamlessly create or edit availabilities and appointments with just a few clicks.</Typography>
      <video autoPlay loop muted controls>
        <source type="video/mp4" src={step3file} />
        Your browser does not support the video tag.
      </video>
    </Stack>
  )
}

const step4 = stepFabric({
  title: 'Filter Your Appointments',
  content: (
    <Stack spacing={2}>
      <Typography variant="body1">
        Easily switch between viewing all appointments or only your scheduled ones. This filter helps you manage your calendar more
        efficiently by focusing on what's important.
      </Typography>
    </Stack>
  ),
})

const step5 = stepFabric({
  title: 'Thank You for Exploring the Updates!',
  content: (
    <Typography>
      We appreciate your attention. Enjoy the new features, and don’t hesitate to reach out if you have any feedback or questions.
    </Typography>
  ),
})
