import { deserialize } from 'deserialize-json-api'
import { chain, head } from 'lodash'
import QS from 'query-string'

import api from '@shared/services'
import { keyToString } from '@shared/utils'

export const UserTourKeys = {
  // ['user-tours']
  all: ['user-tours'],

  // ['user-tours', userId]
  user: (userId) => keyToString([...UserTourKeys.all, userId]),

  // ['user-tours', userId, 'lists']
  lists: (userId) => [...UserTourKeys.user(userId), 'lists'],

  // ['user-tours', userId, 'lists', { ...query }]
  list: (userId, query = {}) => [...UserTourKeys.lists(userId), query],

  // ['user-tours', userId, 'tour']
  tours: (userId) => [...UserTourKeys.user(userId), 'tour'],

  // ['user-tours', userId, 'tour', tourKey]
  tour: (userId, tourKey) => keyToString([...UserTourKeys.tours(userId), tourKey]),
}

const UserToursApi = {
  tours: (userId, query, config) =>
    api.get(QS.stringifyUrl({ url: `/users/${userId}/tours`, query }), config).then((res) => {
      // Transform the data to a map of tour names to tour objects for easier access
      const data = deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []
      return chain(data).groupBy('name').mapValues(head).value()
    }),
  tour: (userId, name, config) =>
    api.get(`/users/${userId}/tours/${name}`, config).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  update: (userId, name, finished) =>
    api
      .put(`/users/${userId}/tours/${name}`, { finished })
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
}

export default UserToursApi
