import { styled } from '@mui/material/styles'

export const BackgroundEventColors = {
  businessHours: {
    backgroundColor: 'rgba(209,209,209,0.2)',
  },
  appointmentAvailability: {
    backgroundColor: '#FFF59D',
  },
  adminTimeAvailability: {
    backgroundGradient: 'repeating-linear-gradient(-45deg, #FFE082, #FFE082 12px, #FFF8E1 12px, #FFF8E1 16px)',
  },
  platformUnavailable: {
    backgroundGradient: 'repeating-linear-gradient(-45deg, #E0E0E0, #E0E0E0 12px, #F5F5F5 12px, #F5F5F5 16px)',
  },
}

export const EventColors = {
  adHocAppointment: {
    color: '#4d4e7f',
    backgroundColor: 'rgba(235,235,255,0.85)',
  },
  scheduledAppointment: {
    color: '#004d40',
    backgroundColor: 'rgba(165,214,167,0.85)',
  },
  documentingAppointment: {
    color: '#e65100',
    backgroundColor: 'rgba(255, 224, 178, 0.85)',
  },
  completeAppointment: {
    color: '#282b2a',
    backgroundColor: 'rgba(255,255,255,0.85)',
    borderColor: '#D9D9D9',
  },
  missedAppointment: {
    color: '#7f0000',
    backgroundColor: 'rgba(255,229,229,0.85)',
  },
}

const ExperimentalStyled = styled('div')(({ theme }) => ({
  position: 'relative',

  th: {
    fontWeight: 'normal',
  },

  '& .fc-license-message': {
    display: 'none',
  },

  '& .fc .fc-daygrid .fc-scroller-liquid-absolute': {
    overflow: 'hidden !important',
  },

  '& .fc .fc-timegrid-slot-label-frame': {
    textAlign: 'center',
  },

  '& .fc': {
    '--fc-small-font-size': theme.typography.body2.fontSize,
    '--fc-bg-event-opacity': 0.7,
    '--fc-border-color': theme.palette.divider,
    '--fc-daygrid-event-dot-width': '10px',
    '--fc-today-bg-color': theme.palette.background.paper,
    '--fc-list-event-dot-width': '10px',
    '--fc-event-border-color': theme.palette.primary.dark,
    '--fc-now-indicator-color': theme.palette.error.main,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    fontFamily: theme.typography.fontFamily,
  },

  '& .fc .fc-daygrid-day-top': {
    display: 'grid',
    '& .fc-daygrid-day-number': {
      textAlign: 'center',
      marginTop: '12px',
      marginBottom: '12px',
    },
  },

  '& .fc .fc-day-today': {
    backgroundColor: theme.palette.primary.lighter,
  },

  '& .fc-timegrid-col-events': {
    margin: 0,
  },

  '& .fc-direction-ltr .fc-daygrid-event.fc-event-end, & .fc-direction-rtl .fc-daygrid-event.fc-event-start': {
    marginLeft: '4px',
    marginBottom: '6px',
    borderRadius: '4px',
    backgroundColor: theme.palette.primary.main,
    border: 'none',
  },

  '& .fc-h-event .fc-event-main': {
    padding: '4px 0 4px 8px',
  },

  '& .fc .fc-v-event .fc-event-title': {
    whiteSpace: 'nowrap',
  },

  '& .fc .fc-timegrid-event': {
    marginBottom: '3px',
  },

  '& .fc .fc-v-event': {
    border: 'none',
  },

  '& .fc .fc-non-business': {
    background: BackgroundEventColors.businessHours.backgroundColor,
  },

  '& .fc .availability-slot': {
    backgroundColor: BackgroundEventColors.appointmentAvailability.backgroundColor,
  },

  '& .fc .blackout-slot': {
    boxShadow: 'none',
    background: BackgroundEventColors.platformUnavailable.backgroundGradient,
    '& .fc-event-main': {
      color: theme.palette.text.primary,
    },
  },

  '& .fc .admin-time-slot': {
    boxShadow: 'none',
    background: BackgroundEventColors.adminTimeAvailability.backgroundGradient,
  },

  '& .fc .ad-hoc-appointment-slot, & .fc .appointment-slot, & .fc .appointment-slot.documenting, & .fc .appointment-slot.missed': {
    boxShadow: 'none',
    borderTop: '2px solid',
    '& .fc-event-main': {
      fontWeight: 'bold',
    },
  },

  '& .fc .ad-hoc-appointment-slot': {
    backgroundColor: EventColors.adHocAppointment.backgroundColor,
    borderTopColor: EventColors.adHocAppointment.color,
    '& .fc-event-main': {
      color: EventColors.adHocAppointment.color,
    },
  },

  '& .fc .appointment-slot': {
    backgroundColor: EventColors.scheduledAppointment.backgroundColor,
    borderTopColor: EventColors.scheduledAppointment.color,
    '& .fc-event-main': {
      color: EventColors.scheduledAppointment.color,
    },
  },

  '& .fc .appointment-slot.documenting': {
    backgroundColor: EventColors.documentingAppointment.backgroundColor,
    borderTopColor: EventColors.documentingAppointment.color,
    '& .fc-event-main': {
      color: EventColors.documentingAppointment.color,
    },
  },

  '& .fc .appointment-slot.missed': {
    backgroundColor: EventColors.missedAppointment.backgroundColor,
    borderTopColor: EventColors.missedAppointment.color,
    '& .fc-event-main': {
      color: EventColors.missedAppointment.color,
    },
  },

  '& .fc .appointment-slot.complete': {
    border: '1px solid',
    borderColor: EventColors.completeAppointment.borderColor,
    backgroundColor: EventColors.completeAppointment.backgroundColor,
    '& .fc-event-main': {
      color: theme.palette.text.primary,
    },
  },

  '& .fc .fc-bg-event': {
    borderRadius: '1px',
    margin: '3px',
  },

  '& .fc .fc-highlight': {
    backgroundColor: theme.palette.primary.light,
    opacity: 'var(--fc-bg-event-opacity)',
  },

  '& .fc .fc-more-popover': {
    border: 'none',
    borderRadius: '6px',
    zIndex: 1200,
  },

  '& .fc .fc-more-popover .fc-popover-body': {
    backgroundColor: theme.palette.grey[200],
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },

  '& .fc .fc-popover-header': {
    padding: '12px',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
  },

  '& .fc-theme-standard .fc-list-day-cushion': {
    backgroundColor: theme.palette.grey[100],
  },

  '& .fc .fc-timeGridDay-view .fc-timegrid-slot': {
    backgroundColor: theme.palette.background.paper,
  },

  '& .fc .fc-list-event:hover td': {
    backgroundColor: theme.palette.grey[100],
  },

  '& .fc .fc-event:hover': {
    cursor: 'pointer',
    boxShadow: theme.customShadows.z2,
  },

  '--fc-event-bg-color': theme.palette.grey[500],
}))

export default ExperimentalStyled
