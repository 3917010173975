import { useMemo } from 'react'

import useCurrentUserId from '@shared/hooks/src/useCurrentUserId'
import usePubNub, { Channels } from '@shared/hooks/src/usePubNub'
import useQuery from '@shared/hooks/src/useQuery'
import MeSharedProvider from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import UsersSharedApi, { MeKey } from '@shared/services/src/Users.api'
import { UserRole } from '@shared/utils'

import PharmaciesApi, { PharmacyKeys, PharmacyTaskKeys } from '@services/Pharmacies.api'
import { ProviderAssessmentsKey, ProviderLabsKeys, ProviderPARTasksKeys } from '@services/Providers.api'
import { TaskKeys } from '@services/Tasks.api'

/**
 * Wraps shared MeProvider with a query to fetch the current user,
 * and subscribes to PubNub events for the current user.
 */
export default function MeProvider({ children }) {
  const userId = useCurrentUserId()

  const { data } = useQuery({
    queryKey: MeKey(userId),
    queryFn: UsersSharedApi.me,
  })
  const { data: pharmacy } = useQuery({
    queryKey: PharmacyKeys.pharmacy(data?.pharmacyId),
    queryFn: () => PharmaciesApi.details(data?.pharmacyId),
    enabled: Boolean(data) && data.role === UserRole.Pharmacy && Boolean(data.pharmacyId),
  })

  const user = useMemo(() => {
    if (data?.role === UserRole.Pharmacy) {
      return { ...data, pharmacy }
    }

    return data
  }, [data, pharmacy])

  const id = user?.id
  const providerId = user?.provider?.id
  const key = MeKey(id)

  usePubNub(
    `user_${id}`,
    ({ action, attributes }) => {
      if (action === Channels.NewMessage) {
        return queryClient.setQueryData(key, (prev) => ({
          ...prev,
          unreadConversationsCount: attributes.unread_conversations,
        }))
      }

      if (action === Channels.UnreviewedLabs) {
        return queryClient.setQueryData(key, (prev) => ({
          ...prev,
          unreviewedLabsCount: attributes.count,
        }))
      }

      if (action === Channels.UpdateQuarterlyFlowCount) {
        return queryClient.setQueryData(key, (prev) => ({
          ...prev,
          pendingFlows: attributes.pending_count,
        }))
      }

      if (action === Channels.ReassignedPatient) {
        if (user?.role === UserRole.Pharmacy) {
          queryClient.invalidateQueries({ queryKey: PharmacyTaskKeys.lists() })
          queryClient.invalidateQueries({ queryKey: PharmacyTaskKeys.tasks() })
        }
        if (user?.role === UserRole.Provider && providerId) {
          queryClient.invalidateQueries({ queryKey: TaskKeys.lists(id) })
          queryClient.invalidateQueries({ queryKey: ProviderLabsKeys.lists(providerId) })
          queryClient.invalidateQueries({ queryKey: ProviderAssessmentsKey.lists(providerId) })
          queryClient.invalidateQueries({ queryKey: ProviderPARTasksKeys.lists(providerId) })
        }
      }
    },
    { enabled: Boolean(id) }
  )

  return <MeSharedProvider value={user}>{children}</MeSharedProvider>
}
