import { useInfiniteQuery } from '@tanstack/react-query'

import { useMe } from '@shared/providers/src/MeProvider'
import { flatten, pageParam } from '@shared/utils'

import MessageMentionsApi, { MessageMentionsKeys } from '@services/MessageMentions.api'

const LIMIT = 20

export function useMentions(params = {}, options = {}) {
  const me = useMe()
  const query = { ...params, order: 'desc', limit: LIMIT }
  return useInfiniteQuery({
    queryKey: MessageMentionsKeys.list(me.id, query),
    queryFn: ({ pageParam }) => MessageMentionsApi.list(me.id, { ...query, offset: pageParam * LIMIT }),
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(LIMIT),
    ...options,
  })
}
