import useQuery from '@shared/hooks/src/useQuery'

import PlatformNotificationsApi, { PlatformNotificationsKeys } from '@services/PlatformNotifications.api'

export function usePlatformNotification(options = {}) {
  const query = { limit: 1, active: true, order: 'desc' }

  return useQuery({
    queryKey: PlatformNotificationsKeys.list(query),
    queryFn: () => PlatformNotificationsApi.list(query),
    select: (data) => data?.[0],
    ...options,
  })
}
