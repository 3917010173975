import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'
import { keyToString } from '@shared/utils'

export const AppointmentKey = (id) => keyToString(['appointments', id])

const AppointmentsApi = {
  getVideoToken: (appointmentId, identity) =>
    api.get(QS.stringifyUrl({ url: `/appointments/${appointmentId}/video_token`, query: { identity } })).then((resp) => resp.data),
  appointment: (appointmentId, config) =>
    api.get(`/appointments/${appointmentId}`, config).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
}

export default AppointmentsApi
