import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'
import { keyToString } from '@shared/utils'

// User Notifications Query Key factory
export const UserNotificationsKeys = {
  // ['user-notifications']
  all: ['user-notifications'],

  // ['user-notifications', userId]
  user: (userId) => keyToString([...UserNotificationsKeys.all, userId]),

  // ['user-notifications', userId, 'lists']
  lists: (userId) => [...UserNotificationsKeys.user(userId), 'lists'],

  // ['user-notifications', userId, 'lists', { ...query }]
  list: (userId, query) => [...UserNotificationsKeys.lists(userId), query],
}

const UserNotificationsApi = {
  list: (userId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/users/${userId}/user_notifications`, query }, { arrayFormat: 'bracket' }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  update: (userId, notificationId) =>
    api
      .patch(`/users/${userId}/user_notifications/${notificationId}`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  markRead: (userId, notificationId) =>
    api
      .patch(`/users/${userId}/user_notifications/${notificationId}/mark_read`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  markUnread: (userId, notificationId) =>
    api
      .patch(`/users/${userId}/user_notifications/${notificationId}/mark_unread`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  markAllRead: (userId, data) =>
    api
      .post(`/users/${userId}/user_notifications/mark_all_read`, data)
      .then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),

  markAllUnread: (userId, data) =>
    api
      .post(`/users/${userId}/user_notifications/mark_all_unread`, data)
      .then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),
}

export default UserNotificationsApi
