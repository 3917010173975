import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'
import { keyToString } from '@shared/utils'

// Platform Notifications Query Key factory
export const PlatformNotificationsKeys = {
  // ['platform-notifications']
  all: ['platform-notifications'],

  // ['platform-notifications', 'lists']
  lists: () => [...PlatformNotificationsKeys.all, 'lists'],

  // ['platform-notifications', 'lists', { ...query }]
  list: (query) => [...PlatformNotificationsKeys.lists(), query],

  // ['platform-notifications', 'notification']
  notifications: () => [...PlatformNotificationsKeys.all, 'notification'],

  // ['platform-notifications', 'notification', notificationId]
  notification: (notificationId) => keyToString([...PlatformNotificationsKeys.notifications(), notificationId]),
}

const PlatformNotificationsApi = {
  list: (query) =>
    api
      .get(QS.stringifyUrl({ url: '/platform_notifications', query }, { arrayFormat: 'bracket' }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  create: (data) =>
    api.post('/platform_notifications', data).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  update: (notificationId, data) =>
    api
      .put(`/platform_notifications/${notificationId}`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
}

export default PlatformNotificationsApi
