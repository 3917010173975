import { baseExtensions } from '@shared/messaging/src/RichTextHelper'

import MentionExtension from './MentionExtension'
import QPhrasesExtension from './QPhrasesExtension'

export const extensions = [...baseExtensions, MentionExtension()]

export const getExtensions = ({ conversationId = null, enableMentions = false, enableQPhrases = false } = {}) => [
  ...baseExtensions,
  ...(enableQPhrases ? [QPhrasesExtension] : []),
  ...(enableMentions ? [MentionExtension(conversationId)] : []),
]
