import { useRef, useState } from 'react'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { styled } from '@mui/material/styles'
import { DatePicker } from '@mui/x-date-pickers-pro'

import { getTestId } from '@shared/utils'

import { CaretDownOutlinedIcon } from '@icons'
import { Button, Paper, Popover, Stack } from '@mui-components'

DateSelect.propTypes = {
  /** Label of the button */
  label: PropTypes.string.isRequired,

  /** Callback fired when the value changes */
  onChange: PropTypes.func.isRequired,

  /** Dayjs value */
  value: PropTypes.object,
}

/**
 * Button opens a dialog to select or manually write a date
 *
 * @example
 * <DateSelect label="Date" value={value} onChange={handleChange} />
 */
export default function DateSelect({ label, onChange, value, ...other }) {
  const ref = useRef()
  const [open, setOpen] = useState(false)

  const testId = getTestId(other, `date-select-${label}`)

  return (
    <>
      <Button ref={ref} color="inherit" endIcon={<DownIcon />} onClick={() => setOpen(true)} data-testid={`${testId}-button`} {...other}>
        {label}
      </Button>
      <Popover
        id="date-popper"
        open={open}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Content initialValue={value} onClose={() => setOpen(false)} onChange={onChange} data-testid={testId} />
      </Popover>
    </>
  )
}

function Content({ initialValue, onChange, onClose, ...rest }) {
  const testId = getTestId(rest)

  const formik = useFormik({
    initialValues: { date: initialValue },
    validationSchema: Yup.object().shape({
      date: Yup.lazy(() =>
        Yup.date().min('1901-01-01', 'Must be after 01/01/1901').nullable().typeError('Invalid Date').required('Date of Birth is required')
      ),
    }),
    onSubmit: (values) => {
      onChange(values.date)
      onClose()
    },
  })

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Paper elevation={4} sx={{ p: 2 }} data-testid={`${testId}-paper`}>
        <Stack spacing={1}>
          <DatePicker
            autoFocus
            minDate={dayjs('1901-01-01')}
            value={formik.values.date}
            onChange={(date) => formik.setFieldValue('date', date, true)}
            slotProps={{
              textField: {
                fullWidth: true,
                name: 'date',
                helperText: formik.touched.date && formik.errors.date,
                error: Boolean(formik.touched.date && formik.errors.date),
                inputProps: { 'data-testid': `${testId}-input` },
              },
              openPickerButton: { 'data-testid': `${testId}-picker` },
            }}
          />
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button onClick={onClose} data-testid={`${testId}-cancel`}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" disabled={!formik.dirty} data-testid={`${testId}-submit`}>
              Ok
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </form>
  )
}

const DownIcon = styled(CaretDownOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))
