import { createContext, useContext } from 'react'

import useQuery from '@shared/hooks/src/useQuery'
import LookupApi from '@shared/services/src/Lookup.api'
import { allDeserialized } from '@shared/services/src/Lookup.mocks'

/**
 * Provide a context for all dropdown options.
 */
export default function DropdownOptionsProvider({ children }) {
  const { data } = useQuery({
    queryKey: ['dropdown-options'],
    queryFn: LookupApi.all,
    select,
  })

  return <DropdownOptionsContext.Provider value={data}>{children}</DropdownOptionsContext.Provider>
}

export function DropdownOptionsMockedProvider({ children }) {
  return <DropdownOptionsContext.Provider value={select(allDeserialized)}>{children}</DropdownOptionsContext.Provider>
}

export const DropdownOptionsContext = createContext(undefined)

export const useDropdownOptions = () => useContext(DropdownOptionsContext)

/**
 * Return a value for the given key
 *
 * @example:
 * const data = useLookup('key')
 *
 * @param key - The dropdown options key
 * @param select - A function to map the value, like in react-query
 * @returns {*} - The dropdown options value
 */
export const useLookup = (key, select) => {
  const value = useDropdownOptions()?.[key]
  if (select) return select(value)
  return value
}

export const Lookup = {
  StatusChangingReasons: 'reasons_for_changing_status',
  NoLongerInterestedReasons: 'no_longer_interested',
  IncompleteApptReasons: 'incomplete_appt_reasons',
  TechnicalIssuesReasons: 'technical_issues_reasons',
  IneligibleApptReasons: 'ineligible_appt_reasons',
  ProviderSuffixes: 'provider_suffixes',
  GenderList: 'patient_genders',
  SexList: 'born_as',
  Timezones: 'timezones',
  EncounterTypes: 'encounter_types',
  ProviderTypes: 'provider_types',
  ServiceLines: 'service_lines',
  AssessmentTypes: 'assessment_types',
  ActivityEventCategories: 'patient_event_categories',
  MATasks: 'ma_tasks',
  CSTasks: 'cs_tasks',
  ProviderTasks: 'provider_tasks',
  PharmacyTasks: 'pharmacy_tasks',
  RXRequiredTasks: 'rx_required_tasks',
  IncompleteLabDeclineReasons: 'reasons_for_returning_incomplete_labs',
  SOPCategories: 'sop_categories',
  SOPPolicies: 'sop_policies',
  TaskExpiredReasons: 'task_expired_reasons',
  PriorAuthDoneReasons: 'prior_auth_done_reasons',
  GileadTaskDoneReasons: 'gilead_done_reasons',
  LabStatuses: 'lab_statuses',
  PrescriptionStatuses: 'prescription_statuses',
  RXStatusesForPharmacy: 'rx_statuses_for_pharmacy',
  LabReviewStatuses: 'lab_review_statuses',
  ReasonsPendingQuestOrder: 'reasons_pending_quest_order',
  ReasonsForMedication: 'soap_reasons_for_medications',
  PriorAuthTemplates: 'prior_auth_templates',
  PatientAtRiskDoneReasons: 'par_done_reasons',
  PEPPatientAtRiskDoneReasons: 'pep_par_done_reasons',
  HIVPatientAtRiskDoneReasons: 'hiv_par_done_reasons',
  SoapLabResultsPositiveForSti: 'soap_lab_results_positive_for_sti',
  AppointmentCommunicationTypes: 'appointment_communication_types',
  MorbiditySyphilisStage: 'doh_morbidity_syphilis_stage',
  MorbiditySymptomsSigns: 'doh_morbidity_symptoms_signs',
  RoiMedicalRecordsEntities: 'roi_medical_records_entities',
  LabFollowUpTaskResolveReasons: 'lab_follow_up_task_resolve_reasons',
}

const select = (data) => {
  return data.reduce((acc, curr) => {
    if ([Lookup.ProviderTypes, Lookup.ServiceLines, Lookup.RXStatusesForPharmacy, Lookup.LabReviewStatuses].includes(curr.key)) {
      acc[curr.key] = curr.value.reduce((acc, [id, value]) => ({ ...acc, [value]: id }), {})
    } else {
      acc[curr.key] = curr.value
    }
    return acc
  }, {})
}
