import { keepPreviousData, useInfiniteQuery, useMutation } from '@tanstack/react-query'

import { queryClient } from '@shared/providers/src/QueryClientProvider'
import { flatten, handleError, pageParam } from '@shared/utils'

import AppointmentsApi, { AppointmentNotesKeys } from '@services/Appointments.api'

const NOTES_LIMIT = 5

export function useAdHocAppointmentNotes(providerId, appointmentId) {
  const query = { limit: NOTES_LIMIT }
  return useInfiniteQuery({
    queryKey: AppointmentNotesKeys.list(appointmentId, query),
    queryFn: ({ pageParam }) => AppointmentsApi.adHocNotes(providerId, appointmentId, { ...query, offset: pageParam * NOTES_LIMIT }),
    placeholderData: keepPreviousData,
    enabled: Boolean(appointmentId),
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(NOTES_LIMIT),
  })
}

export function useAdHocAppointmentNoteCreation(providerId, appointmentId) {
  return useMutation({
    mutationFn: (message) => AppointmentsApi.createAdHocNote(providerId, appointmentId, message),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: AppointmentNotesKeys.lists(appointmentId) }),
    onError: handleError,
  })
}
