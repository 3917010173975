import QS from 'query-string'

import { PossibleRedirects } from '@shared/utils'

export const getMessages = ({ patientId, appointmentId }) => {
  const uploadIdURL = QS.stringifyUrl({
    url: `/app/redirects/${PossibleRedirects.UploadId}`,
    query: { patientId, appointmentId },
  })
  const uploadInsuranceURL = QS.stringifyUrl({
    url: `/app/redirects/${PossibleRedirects.UploadInsurance}`,
    query: { patientId, appointmentId },
  })

  return {
    "I'll be with you shortly": {
      type: 'doc',
      content: [{ type: 'paragraph', content: [{ type: 'text', text: "I'll be with you shortly - thanks for your patience." }] }],
    },
    'Upload ID': {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: "We are required to have a copy of your photo ID (Driver's License, Passport, or Work/School ID) on file to provide your care via telehealth. Please ",
            },
            {
              type: 'text',
              marks: [
                {
                  type: 'link',
                  attrs: {
                    href: uploadIdURL,
                    rel: 'noopener noreferrer nofollow',
                    class: null,
                  },
                },
              ],
              text: 'upload a photo ID',
            },
            { type: 'text', text: ' now or have one with you at the time of our visit.' },
          ],
        },
        { type: 'paragraph' },
        {
          type: 'paragraph',
          content: [{ type: 'text', text: 'Instructions on uploading an ID:' }],
        },
        {
          type: 'orderedList',
          attrs: { start: 1 },
          content: [
            {
              type: 'listItem',
              content: [{ type: 'paragraph', content: [{ type: 'text', text: 'Please log in to your account.' }] }],
            },
            {
              type: 'listItem',
              content: [
                {
                  type: 'paragraph',
                  content: [
                    {
                      type: 'text',
                      text: 'Once logged in, click the three lines in the upper right-hand corner to open the menu and click "',
                    },
                    {
                      type: 'text',
                      marks: [
                        {
                          type: 'link',
                          attrs: {
                            href: uploadIdURL,
                            rel: 'noopener noreferrer nofollow',
                            class: null,
                          },
                        },
                      ],
                      text: 'My Profile',
                    },
                    { type: 'text', text: '".' },
                  ],
                },
              ],
            },
            {
              type: 'listItem',
              content: [
                {
                  type: 'paragraph',
                  content: [
                    { type: 'text', text: 'Once there, you should see the option to ' },
                    {
                      type: 'text',
                      marks: [
                        {
                          type: 'link',
                          attrs: {
                            href: uploadIdURL,
                            rel: 'noopener noreferrer nofollow',
                            class: null,
                          },
                        },
                      ],
                      text: 'upload your ID',
                    },
                    { type: 'text', text: ' at the bottom of the page.' },
                  ],
                },
              ],
            },
          ],
        },
        { type: 'paragraph' },
        { type: 'paragraph', content: [{ type: 'text', text: 'See you in a bit!' }] },
      ],
    },
    'Upload Insurance': {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            { type: 'text', text: 'Please ' },
            {
              type: 'text',
              marks: [
                {
                  type: 'link',
                  attrs: {
                    href: uploadInsuranceURL,
                    rel: 'noopener noreferrer nofollow',
                    class: null,
                  },
                },
              ],
              text: 'upload a copy of your insurance card',
            },
            { type: 'text', text: ' or have one available with you at the time of your visit.' },
          ],
        },
        {
          type: 'paragraph',
          content: [{ type: 'hardBreak' }, { type: 'text', text: 'Instructions on uploading your insurance:' }],
        },
        {
          type: 'orderedList',
          attrs: { start: 1 },
          content: [
            {
              type: 'listItem',
              content: [{ type: 'paragraph', content: [{ type: 'text', text: 'Please log in to your account.' }] }],
            },
            {
              type: 'listItem',
              content: [
                {
                  type: 'paragraph',
                  content: [
                    {
                      type: 'text',
                      text: 'Once logged in, click the three lines in the upper right-hand corner to open the menu and click "',
                    },
                    {
                      type: 'text',
                      marks: [
                        {
                          type: 'link',
                          attrs: {
                            href: uploadInsuranceURL,
                            rel: 'noopener noreferrer nofollow',
                            class: null,
                          },
                        },
                      ],
                      text: 'My Profile',
                    },
                    { type: 'text', text: '".' },
                  ],
                },
              ],
            },
            {
              type: 'listItem',
              content: [
                {
                  type: 'paragraph',
                  content: [
                    { type: 'text', text: 'Once there, you should see the “' },
                    {
                      type: 'text',
                      marks: [
                        {
                          type: 'link',
                          attrs: {
                            href: uploadInsuranceURL,
                            rel: 'noopener noreferrer nofollow',
                            class: null,
                          },
                        },
                      ],
                      text: 'Insurance',
                    },
                    { type: 'text', text: '” option to upload your insurance card.' },
                  ],
                },
              ],
            },
          ],
        },
        { type: 'paragraph' },
        { type: 'paragraph', content: [{ type: 'text', text: 'See you in a bit!' }] },
      ],
    },
    'Upload ID and Insurance': {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            { type: 'text', text: "I'll be with you shortly - if able, please " },
            {
              type: 'text',
              marks: [
                {
                  type: 'link',
                  attrs: {
                    href: uploadIdURL,
                    rel: 'noopener noreferrer nofollow',
                    class: null,
                  },
                },
              ],
              text: 'upload a form of ID',
            },
            { type: 'text', text: " (Driver's License, etc.) and your " },
            {
              type: 'text',
              marks: [
                {
                  type: 'link',
                  attrs: {
                    href: uploadInsuranceURL,
                    rel: 'noopener noreferrer nofollow',
                    class: null,
                  },
                },
              ],
              text: 'insurance card',
            },
            { type: 'text', text: ' (if any) in the portal.' },
          ],
        },
      ],
    },
    'Running Late': {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: 'Sorry I am running later than usual - I appreciate your patience. If we need to reschedule due to this delay, please let me know.',
            },
          ],
        },
      ],
    },
    Reschedule: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: 'Thanks for waiting for your appointment. Unfortunately the existing appointment will time out at 12 minutes, and I cannot override this limitation, but I am eager to see you! I will make every effort to accommodate you as soon as I finish with my current patient.',
            },
          ],
        },
      ],
    },
  }
}
