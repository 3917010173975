export default function Dialog(theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            backgroundColor: theme.palette.background.backdrop,
          },
          '& .MuiDialogContent-root': {
            padding: theme.spacing(3),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(2),
            },
            [theme.breakpoints.down('sm')]: {
              padding: theme.spacing(1),
            },
          },
          '& .MuiDialogActions-root.MuiDialogActions-spacing': {
            padding: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
              padding: theme.spacing(1),
            },
          },
        },
      },
    },
  }
}
