import { getTestId } from '@shared/utils'

import { Skeleton, Stack, Typography } from '@mui-components'
import Avatar from '@components/Avatar'

export default function CBO({ small = false, data, children, ...rest }) {
  if (!data) return null

  const testId = getTestId(rest, `cbo-${data.name}`)
  const subtitle = [data.city, data.state].filter(Boolean).join(', ')

  return (
    <Stack direction="row" spacing={1} alignItems="center" data-testid={testId}>
      <Avatar.CBO card data={data} size={small ? 'sm' : 'md'} />
      <Stack spacing={-0.5}>
        <Typography variant={small ? 'body2' : 'body1'} data-testid={`${testId}-name`}>
          {data.name}
        </Typography>
        {children ||
          (subtitle && (
            <Typography variant={small ? 'body2' : 'body1'} color="text.secondary" data-testid={`${testId}-subtitle`}>
              {subtitle}
            </Typography>
          ))}
      </Stack>
    </Stack>
  )
}

CBO.Loading = function ({ small }) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Skeleton variant="rounded" width={small ? 32 : 40} height={small ? 32 : 40} />
      <Stack spacing={-0.5}>
        <Typography variant={small ? 'body2' : 'body1'}>
          <Skeleton width={150} />
        </Typography>
        <Typography variant={small ? 'body2' : 'body1'}>
          <Skeleton width={100} />
        </Typography>
      </Stack>
    </Stack>
  )
}
