import { Link as RouterLink } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

import { RoleGuard } from '@shared/providers/src/MeProvider'
import { UserRole } from '@shared/utils'

import { BusinessIcon, LocationIcon, MedicalServiceIcon } from '@icons'
import { Divider, Link, Skeleton, Stack, Typography } from '@mui-components'
import { rubyLink } from '@config'

export default function Content({ cbo }) {
  const { primaryPharmacy, secondaryPharmacy } = cbo
  const theme = useTheme()

  const address = [cbo.city, cbo.state].filter(Boolean).join(', ')
  const vendors = (cbo.labVendors || []).map((v) => v.name).join(', ')
  const services = (cbo.cbosProviderTypes || cbo.providerTypes || []).map((v) => v.name).join(', ')

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
        <Stack direction="row" spacing={1}>
          <Typography color="text.secondary">ID</Typography>
          <RoleGuard allowed={[UserRole.Admin]}>
            <Link href={rubyLink(`cbos/${cbo.id}`)} fontWeight="bold" target="_blank">
              {cbo.id}
            </Link>
          </RoleGuard>
          <RoleGuard prohibited={[UserRole.Admin]}>
            <Typography>{cbo.id}</Typography>
          </RoleGuard>
        </Stack>
        <Link component={RouterLink} to={`/app/cbos/${cbo.id}/rule-set`} fontWeight="bold" target="_blank">
          Rule-set
        </Link>
      </Stack>
      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <LocationIcon style={{ fontSize: 16 }} />
          <Typography>{address || '-'}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <BusinessIcon style={{ fontSize: 16 }} />
          <Typography>{vendors || '-'}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <MedicalServiceIcon style={{ fontSize: 16, color: theme.palette.text.secondary }} />
          <Typography>{services || '-'}</Typography>
        </Stack>
      </Stack>
      <Divider />
      <Stack spacing={1}>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Typography>Accepting New Patients</Typography>
          <Typography>{cbo.acceptingNewPatients ? 'Yes' : 'No'}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Typography>Allow Uninsured</Typography>
          <Typography>{cbo.cboPreventUninsured ? 'No' : 'Yes'}</Typography>
        </Stack>
      </Stack>
      {(primaryPharmacy || secondaryPharmacy) && (
        <>
          <Divider />
          <Stack spacing={1}>
            <Typography variant="subtitle2" color="text.secondary" fontWeight="bold">
              Pharmacy
            </Typography>
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Typography>Primary</Typography>
              <Typography>{primaryPharmacy?.name || '-'}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Typography>Secondary</Typography>
              <Typography>{secondaryPharmacy?.name || '-'}</Typography>
            </Stack>
          </Stack>
        </>
      )}
    </>
  )
}

Content.Loading = function () {
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
        <Typography>
          <Skeleton width={100} />
        </Typography>
        <Typography>
          <Skeleton width={80} />
        </Typography>
      </Stack>
      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <LocationIcon style={{ fontSize: 16 }} />
          <Typography>
            <Skeleton width={150} />
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <BusinessIcon style={{ fontSize: 16 }} />
          <Typography>
            <Skeleton width={180} />
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <MedicalServiceIcon style={{ fontSize: 16 }} />
          <Typography>
            <Skeleton width={200} />
          </Typography>
        </Stack>
      </Stack>
      <Divider />
      <Stack spacing={1}>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Typography>Accepting New Patients</Typography>
          <Typography>
            <Skeleton width={80} />
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Typography>Allow Uninsured</Typography>
          <Typography>
            <Skeleton width={80} />
          </Typography>
        </Stack>
      </Stack>
      <Divider />
      <Stack spacing={1}>
        <Typography variant="subtitle2" color="text.secondary" fontWeight="bold">
          Pharmacy
        </Typography>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Typography>Primary</Typography>
          <Typography>
            <Skeleton width={100} />
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Typography>Secondary</Typography>
          <Typography>
            <Skeleton width={100} />
          </Typography>
        </Stack>
      </Stack>
    </>
  )
}
