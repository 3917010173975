import { useState } from 'react'
import { useController, useFieldArray } from 'react-hook-form'
import toast from 'react-hot-toast'
import dayjs from 'dayjs'
import { styled } from '@mui/material/styles'
import { DatePicker } from '@mui/x-date-pickers-pro'

import useDialog from '@shared/hooks/src/useDialog'
import { UserRole } from '@shared/utils'

import { outlineSearchStyling } from '@utils/StylesHelper'
import { DeleteOutlinedIcon } from '@icons'
import { Box, Button, Collapse, Grid, IconButton, InputLabel, Paper, Stack, Typography } from '@mui-components'
import Avatar from '@components/Avatar'
import Confirmation from '@components/Dialog/Confirmation'
import SelectUser from '@components/SelectUser'

export default function Description({ isSubmitting }) {
  const [adding, setAdding] = useState(false)

  const { fields, append, remove } = useFieldArray({ name: 'approvers', keyName: 'tempId' })

  const confirmRemove = useDialog({
    component: Confirmation,
    props: ({ item: index, close }) => {
      return {
        maxWidth: 'xs',
        title: 'Delete Approver?',
        description: 'Do you want to remove this approver? There is no undo.',
        rejectLabel: 'Cancel',
        confirmLabel: 'Delete',
        onReject: () => close(),
        onConfirm: () => {
          remove(index)
          close()
        },
      }
    },
  })

  return (
    <Paper variant="outlined" sx={{ px: 2, py: 3 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={1}>
            <Field name="supersedesDate" label="Supersedes" disabled={isSubmitting} />
            <Field name="approvalDate" label="Approval Date" disabled={isSubmitting} />
            <Field name="originalDate" label="Original Date" disabled={isSubmitting} />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack spacing={1}>
            <Typography>Approver(s)</Typography>
            {fields.length === 0 && (
              <Typography variant="h5" color="text.secondary" data-testid="approvers-empty">
                No approver(s)
              </Typography>
            )}
            {fields.map((field, index) => (
              <User key={field.id} data={field} disabled={isSubmitting} onRemove={() => confirmRemove(index)} />
            ))}
            <Collapse in={adding}>
              <Box sx={outlineSearchStyling}>
                <SelectUser
                  key={fields.length}
                  role={UserRole.Admin}
                  placeholder="Select User"
                  disabled={isSubmitting}
                  onChange={(user) => {
                    if (!user) return
                    if (fields.some((field) => field.id === user.id)) return toast.error('User already in the list')
                    append(user)
                  }}
                  data-testid="approver-selector"
                />
              </Box>
            </Collapse>
            <Button
              variant="dashed"
              color="secondary"
              size="small"
              onClick={() => setAdding((a) => !a)}
              disabled={isSubmitting}
              sx={{ alignSelf: 'center' }}
            >
              Add approver
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  )
}

function User({ data, onRemove, disabled }) {
  const testId = `approver-${data.fullName}`

  return (
    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" data-testid={testId}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Avatar user={data} />
        <Typography variant="h5" data-testid={`${testId}-name`}>
          {data.fullName}
        </Typography>
      </Stack>
      <IconButton onClick={onRemove} disabled={disabled} data-testid={`${testId}-delete`}>
        <DeleteIcon />
      </IconButton>
    </Stack>
  )
}

function Field({ name, label, disabled }) {
  const { field, fieldState } = useController({ name })
  const { ref, onBlur, ...fieldProps } = field

  const error = fieldState.isTouched && Boolean(fieldState.error)

  return (
    <Stack direction="row" spacing={1}>
      <InputLabel htmlFor={field.name} error={error} sx={{ minWidth: 100, pt: 1 }}>
        {label}
      </InputLabel>
      <DatePicker
        {...fieldProps}
        minDate={dayjs('2018-01-01')}
        maxDate={dayjs()}
        inputRef={ref}
        disabled={disabled}
        slotProps={{
          textField: {
            name,
            autoComplete: 'off',
            onBlur,
            error: Boolean(fieldState.error),
            helperText: fieldState.error?.message,
            disabled,
            inputProps: { 'data-testid': `date-input-${name}` },
          },
          openPickerButton: { 'data-testid': `date-picker-${name}` },
        }}
      />
    </Stack>
  )
}

const DeleteIcon = styled(DeleteOutlinedIcon)(({ theme }) => ({
  color: theme.palette.error.main,
}))
