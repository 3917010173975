import { useCallback } from 'react'
import { useLocalStorage } from 'usehooks-ts'

export default function useQPhraseUsage() {
  const [stats, setStats] = useLocalStorage('q-phrases-usage-stat', {})

  const increment = useCallback(
    (id) => {
      if (!id) return
      setStats((stats) => ({
        ...stats,
        [id]: (stats[id] || 0) + 1,
      }))
    },
    [setStats]
  )

  return { stats, increment }
}
