import { useInfiniteQuery, useMutation } from '@tanstack/react-query'
import { createEnumParam, StringParam, withDefault } from 'use-query-params'

import useFiltering from '@shared/hooks/src/useFiltering'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import { filterCache, flatten, mapCache, pageParam } from '@shared/utils'

import useQPhraseUsage from '@hooks/useQPhrasesUsage'
import QPhrasesApi, { QPhrasesKeys } from '@services/QPhrases.api'

export const LIMIT = 50

const ViewParam = createEnumParam(['personal', 'team'])

export function useQPhrasesFilters() {
  return useFiltering({
    phrases: withDefault(ViewParam, 'personal'),
    phrasesSearch: StringParam,
    phraseId: StringParam,
  })
}

export function useQPhrases() {
  const [{ phrases, phrasesSearch }] = useQPhrasesFilters()
  const { stats } = useQPhraseUsage()

  const query = {
    limit: LIMIT,
    category: phrases,
    search_term: phrasesSearch,
    status: 'active',
  }

  return useInfiniteQuery({
    queryKey: QPhrasesKeys.list(query),
    queryFn: ({ pageParam }) => QPhrasesApi.list({ ...query, offset: pageParam * LIMIT }),
    select: (data) => flatten(data).sort((a, b) => (stats[b.id] || 0) - (stats[a.id] || 0)),
    initialPageParam: 0,
    getNextPageParam: pageParam(LIMIT),
  })
}

export function useQPhraseCreation(config) {
  return useMutation({
    mutationFn: (data) => QPhrasesApi.create(data, config),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: QPhrasesKeys.lists() }),
  })
}

export function useQPhraseUpdate(config) {
  return useMutation({
    mutationFn: ({ id, data }) => QPhrasesApi.update(id, data, config),
    onSuccess: (updatedPhrase, { id }) =>
      queryClient.setQueriesData(
        { queryKey: QPhrasesKeys.lists() },
        mapCache((phrase) => (phrase.id === id ? updatedPhrase : phrase))
      ),
  })
}

export function useQPhraseDelete() {
  return useMutation({
    mutationFn: (id) => QPhrasesApi.setStatus(id, { status: 'deleted' }),
    onSuccess: (updatedPhrase, id) =>
      queryClient.setQueriesData(
        { queryKey: QPhrasesKeys.lists() },
        filterCache((phrase) => phrase.id !== id)
      ),
  })
}
