import { forwardRef } from 'react'
import { IMaskInput } from 'react-imask'
import { MaskedNumber } from 'imask'

export default function Mask() {
  return null
}

const PhoneMask = forwardRef(function PhoneMask(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      unmask
      mask="(#00) 000-0000"
      definitions={{ '#': /[1-9]/ }}
      inputRef={ref}
      overwrite
      onAccept={(value) => {
        if (value !== props.value) {
          onChange({ target: { name: props.name, value } })
        }
      }}
    />
  )
})

const SSNMask = forwardRef(function SSNMask(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask="0000"
      inputRef={ref}
      overwrite
      onAccept={(value) => {
        if (value !== props.value) {
          onChange({ target: { name: props.name, value } })
        }
      }}
    />
  )
})

const ZipcodeMask = forwardRef(function ZipcodeMask(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask="00000"
      inputRef={ref}
      onAccept={(value) => {
        if (value !== props.value) {
          onChange({ target: { name: props.name, value } })
        }
      }}
      overwrite
    />
  )
})

const HeightMask = forwardRef(function HeightMask(props, ref) {
  const { onChange, ...other } = props

  // Select the correct mask
  const onMaskDispatch = (appended, dynamicMasked) => {
    const height = (dynamicMasked.value + appended).replace(/\D/g, '')

    return dynamicMasked.compiledMasks.find((m) => m.startsWith.test(height))
  }

  return (
    <IMaskInput
      {...other}
      unmask
      mask={[
        {
          mask: '{4}\' inch"',
          startsWith: /^4/,
          lazy: false,
          autofix: true,
          overwrite: true,
          blocks: {
            inch: {
              mask: MaskedNumber,
              min: 9,
              max: 12,
            },
          },
        },
        {
          mask: 'feet\' inch"',
          startsWith: /^[56]/,
          lazy: false,
          autofix: true,
          overwrite: true,
          blocks: {
            feet: {
              mask: MaskedNumber,
              min: 5,
              max: 6,
            },
            inch: {
              mask: MaskedNumber,
              min: 0,
              max: 12,
            },
          },
        },
        {
          mask: '{7}\' {\\0}"',
          lazy: false,
          autofix: true,
          overwrite: true,
          startsWith: /^7/,
        },
      ]}
      dispatch={onMaskDispatch}
      inputRef={ref}
      onAccept={(value) => {
        if (value !== props.value) {
          onChange({ target: { name: props.name, value } })
        }
      }}
    />
  )
})

const WeightMask = forwardRef(function WeightMask(props, ref) {
  const { onChange, value, ...other } = props
  return (
    <IMaskInput
      {...other}
      unmask
      value={typeof value === 'number' ? value.toString() : value}
      lazy={false}
      mask="num lbs"
      blocks={{
        num: {
          mask: MaskedNumber,
          min: 1,
          max: 999,
        },
      }}
      inputRef={ref}
      onAccept={(value) => {
        if (value !== props.value) {
          onChange({ target: { name: props.name, value } })
        }
      }}
    />
  )
})

const BloodPressureMask = forwardRef(function BloodPressureMask(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      lazy={false}
      mask="num/num"
      blocks={{
        num: {
          mask: MaskedNumber,
          min: 1,
          max: 999,
        },
      }}
      inputRef={ref}
      onAccept={(value) => {
        if (value !== props.value) {
          onChange({ target: { name: props.name, value } })
        }
      }}
    />
  )
})

const IncomeMask = forwardRef(function IncomeMask(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      unmask
      lazy={false}
      mask="num"
      blocks={{
        num: {
          mask: MaskedNumber,
          min: 0,
          max: 999999,
        },
      }}
      inputRef={ref}
      onAccept={(value) => {
        if (value !== props.value) {
          onChange({ target: { name: props.name, value } })
        }
      }}
    />
  )
})

const HouseholdMask = forwardRef(function HouseholdMask(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      unmask
      lazy={false}
      mask="num"
      blocks={{
        num: {
          mask: MaskedNumber,
          min: 0,
          max: 99,
        },
      }}
      inputRef={ref}
      onAccept={(value) => {
        if (value !== props.value) {
          onChange({ target: { name: props.name, value } })
        }
      }}
    />
  )
})

const MoneyMask = forwardRef(function MoneyMask(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      unmask
      lazy={false}
      mask="num"
      blocks={{
        num: {
          mask: MaskedNumber,
          min: 0,
        },
      }}
      inputRef={ref}
      onAccept={(value) => {
        if (value !== props.value) {
          onChange({ target: { name: props.name, value } })
        }
      }}
    />
  )
})

Mask.Height = HeightMask
Mask.BloodPressure = BloodPressureMask
Mask.Phone = PhoneMask
Mask.SSN = SSNMask
Mask.Weight = WeightMask
Mask.Zip = ZipcodeMask
Mask.Income = IncomeMask
Mask.Household = HouseholdMask
Mask.Money = MoneyMask
