import { useState } from 'react'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { useLocalStorage } from 'usehooks-ts'

import usePromiseLoading from '@shared/hooks/src/usePromiseLoading'

import { Popover, Stack } from '@mui-components'
import LinearProgress from '@components/LinearProgress'

import { AppointmentNotice } from '../../Availability.utils'
import { validationSchema } from '../../AvailableItems'
import {
  useAdminTimeCreate,
  useAdminTimeEdit,
  useAdminTimeRemove,
  useAvailabilityCreate,
  useAvailabilityEdit,
  useAvailabilityRemove,
} from '../../Edit/Edit.hooks'
import { ActionButtons, AvailabilityToggleGroup, handleRemoveErrors, handleSubmitErrors, TimeRangePicker } from './AvailabilityForm.utils'

export default function AvailabilityUpdate({ providerId, availability, open, onClose, anchorPosition }) {
  return (
    <Popover
      id={open ? 'availability-popover' : undefined}
      open={open}
      anchorReference={anchorPosition ? 'anchorPosition' : 'none'}
      anchorPosition={anchorPosition}
      disableAutoFocus
      disableRestoreFocus
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <AvailabilityForm providerId={providerId} availability={availability} onClose={onClose} />
    </Popover>
  )
}

function AvailabilityForm({ providerId, availability, onClose }) {
  const [isNotice, setIsNotice] = useState(false)
  const [type, setType] = useLocalStorage('last-used-availability-type', 'availability')

  const isEditingExistingAvailability = Boolean(availability?.id)

  const createAvailability = useAvailabilityCreate(providerId)
  const editAvailability = useAvailabilityEdit(providerId)
  const removeAvailability = useAvailabilityRemove(providerId)

  const createAdminTime = useAdminTimeCreate(providerId)
  const editAdminTime = useAdminTimeEdit(providerId)
  const removeAdminTime = useAdminTimeRemove(providerId)

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      id: availability?.id ?? null,
      start: availability?.start ? dayjs(availability.start) : null,
      end: availability?.end ? dayjs(availability.end) : null,
    },
    validationSchema,
    onSubmit: ({ id, start, end }, { setFieldError }) => {
      return update({
        id,
        type: availability?.type || type,
        start: start.format('HH:mm'),
        end: end.format('HH:mm'),
      }).catch((e) => handleSubmitErrors(e, setFieldError, isEditingExistingAvailability, formik.resetForm, setIsNotice))
    },
  })

  const update = ({ type, start, end }) => {
    let mutation
    if (type === 'availability') mutation = isEditingExistingAvailability ? editAvailability : createAvailability
    if (type === 'adminTime') mutation = isEditingExistingAvailability ? editAdminTime : createAdminTime

    return mutation({
      id: availability.id,
      date_formatted: dayjs(availability.start).format('YYYY-MM-DD'),
      start_time_formatted: start,
      end_time_formatted: end,
    }).then(onClose)
  }

  const [remove, removing] = usePromiseLoading(() => {
    let mutation
    if (availability.type === 'availability') mutation = removeAvailability
    if (availability.type === 'adminTime') mutation = removeAdminTime

    return mutation(availability.id)
      .then(onClose)
      .catch((e) => handleRemoveErrors(e, setIsNotice))
  })

  const isLoading = formik.isSubmitting || removing

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <AppointmentNotice
        providerId={providerId}
        dateRange={[dayjs(availability?.start), dayjs(availability?.end)]}
        time={formik.values}
        open={isNotice}
        onClose={() => setIsNotice(false)}
      />
      <LinearProgress loading={isLoading} />

      <Stack p={2} spacing={2}>
        <AvailabilityToggleGroup
          value={isEditingExistingAvailability ? availability.type : type}
          onChange={setType}
          disabled={isEditingExistingAvailability}
        />

        <TimeRangePicker
          start={formik.values.start}
          end={formik.values.end}
          onStartChange={(start) => formik.setFieldValue('start', start)}
          onEndChange={(end) => formik.setFieldValue('end', end)}
          errors={formik.errors}
          touched={formik.touched}
          isLoading={isLoading}
        />

        <ActionButtons
          isLoading={isLoading}
          isEditing={isEditingExistingAvailability}
          onCancel={onClose}
          isRemoving={removing}
          onDelete={remove}
        />
      </Stack>
    </form>
  )
}
