import { useState } from 'react'

import { QPhrasesDrawer } from '@pages/QPhrases'
import { SnippetsOutlinedIcon } from '@icons'
import { Box, IconButton, useMediaQuery } from '@mui-components'

export default function QPhrases() {
  const [open, setOpen] = useState(false)
  const matchDownSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 0.5 }}>
        <IconButton
          id="q-phrases-button"
          color="secondary"
          onClick={() => setOpen((o) => !o)}
          aria-label="QPhrases toggler"
          data-testid="header-q-phrases"
          size={matchDownSm ? 'small' : 'medium'}
          sx={{ color: 'text.primary' }}
        >
          <SnippetsOutlinedIcon style={{ fontSize: 23 }} />
        </IconButton>
      </Box>
      <QPhrasesDrawer open={open} onClose={() => setOpen(false)} />
    </>
  )
}
