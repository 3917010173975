import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

export const AppointmentToggleGroup = ({ value, onChange, disabled }) => {
  return (
    <ToggleButtonGroup
      fullWidth
      color="primary"
      value={value}
      exclusive
      disabled={disabled}
      onChange={(e, type) => {
        if (!type) return
        onChange(type)
      }}
      aria-label="Appointment Type"
      size="small"
    >
      <ToggleButton value="ad-hoc">Ad Hoc Appointment</ToggleButton>
    </ToggleButtonGroup>
  )
}
