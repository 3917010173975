import useUserSetting from '@shared/hooks/src/useUserSetting'
import { checkMessagingAvailability } from '@shared/messaging/src/Helper'
import { useGetConversationId } from '@shared/messaging/src/hooks'
import { ContactMethod, UserRole, UserSettingName } from '@shared/utils'

import { usePatientDirectCall, useUserDirectCall } from '@providers/VoiceCallProvider'
import { MessageOutlinedIcon, PhoneOutlinedIcon } from '@icons'
import { Box, Button, Stack, Tooltip } from '@mui-components'

export default function CommunicationActions({ user }) {
  const { makeCall: callPatient, disabled: patientCallDisabled } = usePatientDirectCall(user)
  const { makeCall: callUser, disabled: userCallDisabled } = useUserDirectCall(user)
  const getConversationId = useGetConversationId({ userId: user?.id }, { skipHandling: true })

  const contactMethodSetting = useUserSetting(user, UserSettingName.ContactMethod)
  const [canMessage, description] = checkMessagingAvailability(user)
  const isPatient = user?.role === UserRole.Patient
  const contactMethod = contactMethodSetting?.value
  const callDisabled = patientCallDisabled || userCallDisabled
  const disabled = !user || user.disabled

  const handleMessage = async () => {
    const data = await getConversationId.mutateAsync()
    const id = data.conversation?.id
    const url = `${import.meta.env.VITE_URL}/app/conversations/${id}`
    window.open(url, '_blank')
  }

  const handleCall = () => {
    if (isPatient) return callPatient()
    return callUser()
  }

  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title={description}>
        <Box sx={{ width: '100%' }}>
          <Button
            fullWidth
            disabled={disabled || !canMessage}
            variant={contactMethod === ContactMethod.Email ? 'contained' : 'dashed'}
            color={contactMethod === ContactMethod.Email ? 'primary' : 'secondary'}
            startIcon={<MessageOutlinedIcon />}
            onClick={handleMessage}
          >
            Message
          </Button>
        </Box>
      </Tooltip>
      <Tooltip title={callDisabled ? `${isPatient ? 'Patient is disabled or' : 'User'} doesn't have phone number on file` : ''}>
        <Box sx={{ width: '100%' }}>
          <Button
            fullWidth
            disabled={disabled || callDisabled}
            variant={contactMethod === ContactMethod.Phone ? 'contained' : 'dashed'}
            color={contactMethod === ContactMethod.Phone ? 'primary' : 'secondary'}
            startIcon={<PhoneOutlinedIcon />}
            onClick={handleCall}
          >
            Call
          </Button>
        </Box>
      </Tooltip>
    </Stack>
  )
}
