export const bornAs = ['Female', 'Intersex', 'Male']
export const pronouns = ['He/Him', 'She/Her', 'They/Them']
export const genders = ['Male', 'Female', 'Transgender Male', 'Transgender Female', 'Gender Queer', 'Nonconforming']
export const sexPartners = ['Male', 'Female', 'Transgender']
export const uniqPartners = ['0', '1-5', '5-10', '10+', 'Unknown']
export const sexType = {
  Bottom: 'Anal Bottom',
  Top: 'Anal Top',
  Oral: 'Oral',
  Vaginal: 'Vaginal',
  'No Answer': 'Prefer to not tell',
}
export const diseases = {
  HepatitisB: 'Hepatitis B',
  HepatitisC: 'Hepatitis C',
  'Kidney Disease': 'Kidney Disease',
  Diabetes: 'Diabetes',
  'Liver Disease': 'Liver Disease',
  None: 'None',
}
export const severities = [
  { label: 'Not Sure', value: 'not_sure' },
  { label: 'Intolerance', value: 'intolerance' },
  { label: 'Mild', value: 'mild' },
  { label: 'Moderate', value: 'moderate' },
  { label: 'Severe', value: 'severe' },
]
export const std = ['Chlamydia', 'Gonorrhea', 'Both', 'None']
export const sexualities = ['Gay', 'Straight', 'Bisexual', 'No Answer']
export const ethnicities = ['Hispanic/Latino', 'Not Hispanic/Latino', 'No Answer']
export const relationships = ['Committed Relationship', 'Open Relationship', 'Single']
export const languages = [
  'American Sign Language',
  'Arabic',
  'Cantonese',
  'English',
  'Haitian',
  'Korean',
  'Mandarin',
  'Spanish',
  'Tagalog',
  'Vietnamese',
  'Other',
]
export const races = [
  'African American / Black',
  'Asian',
  'Caucasian/White',
  'Native American or Alaska Native',
  'Native Hawaiian or Pacific Islander',
  'Prefer not to answer',
]
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
export const commPrefs = ['Text and Email', 'Email', 'Text']
export const ContactMethod = {
  Email: 'email',
  Phone: 'phone',
}

export const AppointmentTypes = {
  Video: 'video',
  Phone: 'phone',
  Async: 'async',
  Other: 'other',
}

export const AppointmentScheduleTypes = {
  Scheduled: 'sched',
  Unscheduled: 'unschd',
  Async: 'async',
  AdHoc: 'ad_hoc',
}

export const states = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'VI',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
]

/**
 * Define the build environments from VITE_BUILD_ENV.
 * Do not change the values of these constants as they are used in the build process.
 */
export const BuildEnv = {
  Local: 'local',
  QA: 'qa',
  Staging: 'staging',
  Production: 'prod',
}

export const TodoType = {
  InitialAppointment: 'APPOINTMENT_NEW',
  PhotoId: 'PHOTO_ID',
  Insurance: 'RX_INSURANCE',
  ProfileIncomplete: 'PROFILE_INCOMPLETE',
  Message: 'UNREAD',
  LabFlow: 'LAB_FLOW',
  Assessment: 'ASSESSMENT',
  LabKitDelivered: 'AT_HOME_LAB_DELIVERED',
  QuestLabOrdered: 'QUEST_LAB_ORDERED',
  LabsReceived: 'LAB_RESULTS_RECEIVED',
  Appointment: 'APPOINTMENT',
  GileadApplication: 'GILEAD_APPLICATION',
  ROIRequest: 'ROI_REQUEST',
  Generic: 'GENERIC',
}

export const GenericTodoType = {
  NewDocument: 'NEW_DOCUMENT',
}

// The order of the todos in the home page
export const TodoTypeOrder = {
  // Decimals are used to allow for inserting new todos in between existing ones
  [TodoType.PhotoId]: 2.5,
  [TodoType.ProfileIncomplete]: 3,
  [TodoType.Message]: 4,
}

export const VoiceCallStatus = {
  Initiated: 'initiated',
  Ringing: 'ringing',
  InProgress: 'in-progress',
  Completed: 'completed',
  Failed: 'failed',
  Busy: 'busy',
  NoAnswer: 'no-answer',
}

export const LabStatus = {
  Ordered: 'Ordered',
  NeedsReview: 'Needs Review',
  NurseReviewed: 'Nurse Reviewed',
  NeedsFollowUp: 'Needs Follow-up',
  Ready: 'Ready',
  OrderFailed: 'Order Failed',
  ShipmentPending: 'Shipment Pending',
  Shipped: 'Shipped',
  InTransitionToPatient: 'In Transit to Patient',
  FailedDeliveryToPatient: 'Failed Delivery To Patient',
  DeliveredToPatient: 'Delivered To Patient',
  InTransitToLab: 'In Transit to Lab',
  ReceivedByLab: 'Received by Lab',
  ReturnedToLab: 'Returned to Lab',
  LabProcessing: 'Lab Processing',
  LabRejected: 'Lab Rejected',
  Pending: 'Pending',
  Requested: 'Requested',
  Cancelled: 'Cancelled',
  Released: 'Released',
}

export const LabTypes = {
  Ash: 'Ash',
  MTL: 'MTL',
  Quest: 'Quest',
  GHMC: 'GMHC',
  Manual: 'Manual',
}

export const EncounterType = {
  PEP: 'PEP',
  HepC: 'Hep C',
  HivEnrollment: 'HIV Enrollment',
  HivFollowUp: 'HIV Follow Up',
  HivInitial: 'HIV Initial',
  HivAdherence: 'HIV Adherence',
  PrepFollowUp: 'PrEP Follow Up',
  PrepInitial: 'PrEP Initial',
  PrepQuarterly: 'PrEP Quarterly',
  STIFollowUp: 'STI Follow Up',
  STITesting: 'STI Testing',
  InjectablePrEP: 'Injectable PrEP',
  Wellness: 'Wellness',
}

export const TaskStatus = {
  ToDo: 'Todo',
  InProgress: 'In Progress',
  Waiting: 'Waiting',
  Completed: 'Completed',
  Expired: 'Expired',
  NotApplicable: 'Not Applicable',
}

export const taskStatusToLabel = {
  [TaskStatus.ToDo]: 'To Do',
  [TaskStatus.InProgress]: 'In Progress',
  [TaskStatus.Waiting]: 'Waiting',
  [TaskStatus.Completed]: 'Done',
  [TaskStatus.Expired]: 'Expired',
  [TaskStatus.NotApplicable]: 'Not Applicable',
}

export const TaskPriority = {
  High: 'high',
  Medium: 'medium',
  Low: 'low',
}

// Constant for the task kinds that have some specific behavior and require conditional checks
export const TaskKind = {
  PriorAuth: 'Prior Auth',
  GileadCustomerSupport: 'Gilead AA Application CS',
  RoiPDF: 'ROI PDF',

  // Provider PAR Tasks
  PAR: 'Patient At Risk',
  PEP_PAR: 'PEP Patient At Risk',
  HIV_PAR: 'HIV Patient At Risk',

  // Provider Tasks
  GileadProvider: 'Gilead AA Application',
  IncompleteLabs: 'Incomplete Labs',
  RxClarification: 'Rx Clarification',
  PendingQuestOrder: 'Pending Quest Order',
  ABNLFollowUp: 'ABNL Lab Follow Up',
}

export const isPARKind = (kind) => [TaskKind.PAR, TaskKind.PEP_PAR, TaskKind.HIV_PAR].includes(kind)
export const isProviderKind = (kind) =>
  [TaskKind.GileadProvider, TaskKind.IncompleteLabs, TaskKind.RxClarification, TaskKind.PendingQuestOrder, TaskKind.ABNLFollowUp].includes(
    kind
  )

export const Keyboard = {
  Enter: 13,
}

export const TreatmentType = {
  PEP: 'PEP',
  OralPrEP: 'PrEP',
  InjectablePrEP: 'Injectable PrEP',
  HIV: 'HIV',
  HepC: 'Hep C',
  STI: 'STI',
  STITesting: 'STI Testing',
}

export const BreakGlassType = {
  Authorized: 'Authorized',
  Required: 'Required',
  NotRequired: 'Not Required',
}

export const UserRole = {
  Admin: 'admin',
  Patient: 'patient',
  Provider: 'provider',
  CBO: 'cbo',
  Pharmacy: 'pharmacy',
  MA: 'assistant',
  Support: 'support',
  Manager: 'manager',
  ClinicalAdmin: 'clinical_admin',
  ClinicalLead: 'clinical_lead',
}

export const userRoleToLabel = {
  [UserRole.Admin]: 'Admin',
  [UserRole.Patient]: 'Patient',
  [UserRole.Provider]: 'Provider',
  [UserRole.CBO]: 'CBO',
  [UserRole.Pharmacy]: 'Pharmacy',
  [UserRole.MA]: 'MA',
  [UserRole.Support]: 'Support',
  [UserRole.Manager]: 'Manager',
  [UserRole.ClinicalAdmin]: 'Clinical Admin',
  [UserRole.ClinicalLead]: 'Clinical Lead',
}

export const NotesEntity = {
  Labs: 'labs',
  Users: 'users',
  Rx: 'prescriptions',
  Encounters: 'encounters',
  Appointments: 'appointments',
  Tasks: 'tasks',
}

export const UserSettingName = {
  AcceptTermsOfService: 'accept_terms_of_service',
  AcceptTelehealthConsent: 'accept_telehealth_consent',
  AcceptPrivacyPolicy: 'accept_privacy_policy',
  AllowHealthcareNotifications: 'allow_healthcare_notifications',
  AllowSharingHealthData: 'allow_sharing_health_data',
  AllowSharingHealthDataGilead: 'allow_sharing_health_data_gilead',
  AvatarPronouns: 'avatar_pronouns',
  ContactMethod: 'contact_method',
  EmailVerified: 'email_verified',
  Initials: 'initials',
  PhoneVerified: 'phone_verified',
  PreferredCommunication: 'preferred_communication',
  SelfEnrollment: 'self_enrollment',
  TimeFormat: 'time_format',
  AccessRights: 'access_rights',
}

export const AppointmentStatus = {
  Scheduled: 'Scheduled',
  Missed: 'Missed',
  Canceled: 'Canceled',
  Waiting: 'Waiting',
  InProgress: 'In progress',
  Documenting: 'Documenting',
  Complete: 'Complete',
  Failed: 'Failed',
}

export const HealthHistoryType = {
  Allergies: 'allergies',
  Conditions: 'conditions',
  Medications: 'medications',
  Surgeries: 'surgeries',
  Immunizations: 'immunizations',
  Vitals: 'vitals',
}

export const VitalType = {
  Weight: 'weight',
  Height: 'height',
  BloodPressure: 'bp',
}

export const MessageReminderCategories = {
  UploadPhotoId: 'upload_photo_id',
}

export const MessageReminderRelatedModels = {
  Assessment: 'Assessment',
  Appointment: 'Appointment',
}

export const VendorType = {
  AtHome: 'At-home Labs',
  InPerson: 'In Person',
}

export const AssessmentStatus = {
  Pending: 'pending',
  InProgress: 'in_progress',
  Expired: 'expired',
  Finished: 'finished',
  Reviewed: 'reviewed',
}

// Duration of the time slot in the calendar in minutes
export const TimeSlotDuration = 10

export const MedicalRecordStatus = {
  // PDF Generation statuses
  Pending: 'pending',
  InProgress: 'in_progress',
  Finished: 'finished',
  // Fax statuses
  FaxQueued: 'fax_queued',
  Faxed: 'faxed',
  FaxFailed: 'fax_failed',
}

// The number of days after which a task is highlighted as overdue
export const TaskErrorThresholdDays = 15
export const TaskWarningThresholdDays = 9

export const PossibleRedirects = {
  UploadId: 'upload-id',
  UploadInsurance: 'upload-insurance',
}

export const OneClickIndicator = {
  Enrolled: 'ENROLLED',
  Declined: 'NOT ENROLLED',
  DeclinedWithTypo: 'NOT ENTROLLED',
  NeedToOffer: 'NEEDS ENROLL OFFERED',
}

export const InsurancePartners = {
  PrideLife: 'pride_life',
  AmericanExchange: 'american_exchange',
}
