import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import dayjs from 'dayjs'

import { queryClient } from '@shared/providers/src/QueryClientProvider'
import { extendCache, filterCache, mapCacheWithFn } from '@shared/utils'

import AppointmentsApi, { ProviderAdHocAppointmentsKeys } from '@services/Appointments.api'
import ProvidersApi, { ProviderAdminTimeKeys, ProviderAvailabilitiesKeys } from '@services/Providers.api'

import { FORMAT, LIMIT } from '../Availability.hooks'

export function useAvailabilityRemove(providerId) {
  const remove = useMutation({
    mutationFn: (id) => ProvidersApi.removeAvailability(providerId, id),
  })

  return useCallback(
    (id) => {
      return remove.mutateAsync(id).then(() => {
        queryClient.invalidateQueries({ queryKey: ProviderAvailabilitiesKeys.months(providerId) })
        return queryClient.setQueriesData(
          { queryKey: ProviderAvailabilitiesKeys.lists(providerId) },
          filterCache((a) => a.id !== id)
        )
      })
    },
    [providerId, remove]
  )
}

export function useAvailabilityCreate(providerId) {
  const create = useMutation({
    mutationFn: (data) => ProvidersApi.createAvailability(providerId, data),
  })

  return useCallback(
    (data) => {
      return create.mutateAsync(data).then((newAvailability) => {
        queryClient.invalidateQueries({ queryKey: ProviderAvailabilitiesKeys.months(providerId) })

        queryClient.setQueriesData(
          {
            queryKey: ProviderAvailabilitiesKeys.lists(providerId),
            predicate: ({ queryKey }) => {
              const queryParam = queryKey[queryKey.length - 1]
              if (!queryParam || !queryParam.start_date || !queryParam.end_date) return false
              const start = dayjs(queryParam.start_date, FORMAT)
              const end = dayjs(queryParam.end_date, FORMAT)
              return dayjs(data.date_formatted, 'YYYY-MM-DD').isBetween(start, end, 'day', '[]')
            },
          },
          extendCache((list) => [...list, newAvailability].sort((a, b) => new Date(a.start) - new Date(b.start)), LIMIT)
        )
      })
    },
    [create, providerId]
  )
}

export function useAvailabilityEdit(providerId) {
  const edit = useMutation({
    mutationFn: ({ id, ...data }) => ProvidersApi.editAvailability(providerId, id, data),
  })

  return useCallback(
    (data) => {
      return edit.mutateAsync(data).then((availability) => {
        return queryClient.setQueriesData(
          { queryKey: ProviderAvailabilitiesKeys.lists(providerId) },
          mapCacheWithFn((list) => {
            const updated = list.map((a) => (a.id === availability.id ? availability : a))
            return [...updated].sort((a, b) => new Date(a.start) - new Date(b.start))
          })
        )
      })
    },
    [edit, providerId]
  )
}

export function useAdminTimeRemove(providerId) {
  const remove = useMutation({
    mutationFn: (id) => ProvidersApi.removeAdminTime(providerId, id),
  })

  return useCallback(
    (id) => {
      return remove.mutateAsync(id).then(() => {
        return queryClient.setQueriesData(
          { queryKey: ProviderAdminTimeKeys.lists(providerId) },
          filterCache((a) => a.id !== id)
        )
      })
    },
    [providerId, remove]
  )
}

export function useAdminTimeCreate(providerId) {
  const create = useMutation({
    mutationFn: (data) => ProvidersApi.createAdminTime(providerId, data),
  })

  return useCallback(
    (data) => {
      return create.mutateAsync(data).then((newTime) => {
        queryClient.setQueriesData(
          {
            queryKey: ProviderAdminTimeKeys.lists(providerId),
            predicate: ({ queryKey }) => {
              const queryParam = queryKey[queryKey.length - 1]
              if (!queryParam || !queryParam.start_date_time || !queryParam.end_date_time) return false
              const start = dayjs(queryParam.start_date_time, FORMAT)
              const end = dayjs(queryParam.end_date_time, FORMAT)
              return dayjs(data.date_formatted, 'YYYY-MM-DD').isBetween(start, end, 'day', '[]')
            },
          },
          extendCache((list) => [...list, newTime].sort((a, b) => new Date(a.start) - new Date(b.start)), LIMIT)
        )
      })
    },
    [create, providerId]
  )
}

export function useAdminTimeEdit(providerId) {
  const edit = useMutation({
    mutationFn: ({ id, ...data }) => ProvidersApi.editAdminTime(providerId, id, data),
  })

  return useCallback(
    (data) => {
      return edit.mutateAsync(data).then((time) => {
        return queryClient.setQueriesData(
          { queryKey: ProviderAdminTimeKeys.lists(providerId) },
          mapCacheWithFn((list) => {
            const updated = list.map((a) => (a.id === time.id ? time : a))
            return [...updated].sort((a, b) => new Date(a.start) - new Date(b.start))
          })
        )
      })
    },
    [edit, providerId]
  )
}

export function useAdHocAppointmentRemove(providerId) {
  const remove = useMutation({
    mutationFn: (appointmentId) => AppointmentsApi.removeAdHoc(providerId, appointmentId),
  })

  return useCallback(
    (id) => {
      return remove.mutateAsync(id).then(() => {
        return queryClient.setQueriesData(
          { queryKey: ProviderAdHocAppointmentsKeys.lists(providerId) },
          filterCache((a) => a.id !== id)
        )
      })
    },
    [providerId, remove]
  )
}

export function useAdHocAppointmentCreate(providerId) {
  const create = useMutation({
    mutationFn: (data) => AppointmentsApi.createAdHoc(providerId, data),
  })

  return useCallback(
    (data) => {
      return create.mutateAsync(data).then((newAppointment) => {
        queryClient.setQueriesData(
          {
            queryKey: ProviderAdHocAppointmentsKeys.lists(providerId),
            predicate: ({ queryKey }) => {
              const queryParam = queryKey[queryKey.length - 1]
              if (!queryParam || !queryParam.start_date || !queryParam.end_date) return false
              const start = dayjs(queryParam.start_date, FORMAT)
              const end = dayjs(queryParam.end_date, FORMAT)
              return dayjs(data.time, 'YYYY-MM-DD').isBetween(start, end, 'day', '[]')
            },
          },
          extendCache((list) => [...list, newAppointment].sort((a, b) => new Date(a.start) - new Date(b.start)), LIMIT)
        )
      })
    },
    [create, providerId]
  )
}

export function useAdHocAppointmentEdit(providerId) {
  const edit = useMutation({
    mutationFn: ({ id, ...data }) => AppointmentsApi.updateAdHoc(providerId, id, data),
  })

  return useCallback(
    (data) => {
      return edit.mutateAsync(data).then((appointment) => {
        return queryClient.setQueriesData(
          { queryKey: ProviderAdHocAppointmentsKeys.lists(providerId) },
          mapCacheWithFn((list) => {
            const updated = list.map((a) => (a.id === appointment.id ? appointment : a))
            return [...updated].sort((a, b) => new Date(a.start) - new Date(b.start))
          })
        )
      })
    },
    [edit, providerId]
  )
}
