import { useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendarBase from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayjs from 'dayjs'
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import { useUpdateEffect } from 'usehooks-ts'
import { styled } from '@mui/material/styles'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import { QuestionCircleOutlinedIcon } from '@icons'
import { Box, Divider, IconButton, Skeleton, Stack, Tooltip, Typography } from '@mui-components'
import { bindDelayedHover } from '@components/UserCard/UserCard.utils'

import { Mode, ScheduleType, slotDuration, stringifyDuration } from './Calendar.utils'
import CalendarStyled, { BackgroundEventColors, EventColors } from './CalendarStyled'

export function Timing({ user, completedEncounters, availabilities, adminTimes }) {
  const data = useMemo(() => {
    if (!user) return undefined
    if (!availabilities || !adminTimes) return undefined

    const maxMinutes = user?.provider.maxHours * 60
    const adminTimeInMinutes = adminTimes?.reduce((acc, { duration }) => acc + duration, 0) || 0
    const apptsTimeInMinutes = availabilities?.reduce((acc, { duration }) => acc + duration, 0) || 0

    return {
      max: maxMinutes,
      adminTime: adminTimeInMinutes,
      apptsTime: apptsTimeInMinutes,
    }
  }, [adminTimes, availabilities, user])

  if (!data) {
    return (
      <Typography variant="body2" align="right">
        <Skeleton width={200} />
        <Skeleton width={200} />
      </Typography>
    )
  }
  const { max, adminTime, apptsTime } = data
  const adminTimeString = stringifyDuration(adminTime)
  const apptsTimeString = stringifyDuration(apptsTime)

  const value = (
    <>
      <br />
      Admin:&nbsp;<b>{adminTimeString}</b> | Appointments:&nbsp;<b>{apptsTimeString}</b> | Completed&nbsp;Encounters:&nbsp;
      <b>{completedEncounters}</b>
    </>
  )

  if (!max) {
    return (
      <Typography variant="body2" align="right">
        Max Hrs not defined
        {value}
      </Typography>
    )
  }
  const remaining = max - adminTime - apptsTime
  const remainingString = stringifyDuration(Math.abs(remaining))
  const maxString = stringifyDuration(max)
  return (
    <Typography variant="body2" align="right">
      Max Hrs: <b>{maxString}</b> | {remaining >= 0 ? 'Remaining' : 'Exceeded'}: <b>{remainingString}</b>
      {value}
    </Typography>
  )
}

export function IndicatorGuide() {
  return (
    <PopupState variant="popover" popupId="indicator-guide-popover">
      {(popupState) => {
        const hoverHandler = isMobile ? bindHover(popupState) : bindDelayedHover(popupState, 250)

        return (
          <div>
            <Stack direction="row" alignItems="center" spacing={1} sx={{ cursor: 'progress' }} {...hoverHandler}>
              <Typography color="text.secondary">Indicator Guide</Typography>
              <IconButton shape="rounded" color="secondary" size="small">
                <QuestionCircleOutlinedIcon style={{ fontSize: 18 }} />
              </IconButton>
            </Stack>
            <HoverPopover {...bindPopover(popupState)} sx={{ '& .MuiPaper-root': { width: 300 } }}>
              <Stack p={2} spacing={2}>
                <ColorScheme />
              </Stack>
            </HoverPopover>
          </div>
        )
      }}
    </PopupState>
  )
}

export function ColorScheme() {
  return (
    <>
      <LegendItem label="Appointment Availability" background={BackgroundEventColors.appointmentAvailability.backgroundColor} />
      <LegendItem label="Admin Availability" background={BackgroundEventColors.adminTimeAvailability.backgroundGradient} />
      <LegendItem label="Platform Unavailable" background={BackgroundEventColors.platformUnavailable.backgroundGradient} />
      <Divider />
      <LegendItem
        label="Ad Hoc Appointment"
        background={EventColors.adHocAppointment.backgroundColor}
        color={EventColors.adHocAppointment.color}
      />
      <LegendItem
        label="Missed Appointment"
        background={EventColors.missedAppointment.backgroundColor}
        color={EventColors.missedAppointment.color}
      />
      <LegendItem
        label="Scheduled Appointment"
        background={EventColors.scheduledAppointment.backgroundColor}
        color={EventColors.scheduledAppointment.color}
      />
      <LegendItem
        label="Documenting Appointment"
        background={EventColors.documentingAppointment.backgroundColor}
        color={EventColors.documentingAppointment.color}
      />
      <LegendItem
        label="Complete Appointment"
        background={EventColors.completeAppointment.backgroundColor}
        color={EventColors.completeAppointment.color}
        borderColor={EventColors.completeAppointment.borderColor}
      />
    </>
  )
}

export const ScheduleTypeToggleGroup = ({ value, onChange, disabled }) => {
  return (
    <ToggleButtonGroup
      fullWidth
      color="primary"
      value={value}
      exclusive
      disabled={disabled}
      onChange={(e, mode) => {
        if (!mode) return
        onChange(mode)
      }}
      aria-label="Schedule Type"
      size="small"
      sx={{ backgroundColor: 'background.paper' }}
    >
      <ToggleButton value={ScheduleType.All}>Show all</ToggleButton>
      <ToggleButton value={ScheduleType.Scheduled}>Scheduled only</ToggleButton>
    </ToggleButtonGroup>
  )
}

export const ModeToggleGroup = ({ value, onChange, disabled }) => {
  return (
    <ToggleButtonGroup
      fullWidth
      color="primary"
      value={value}
      exclusive
      disabled={disabled}
      onChange={(e, mode) => {
        if (!mode) return
        onChange(mode)
      }}
      aria-label="Editing Mode"
      size="small"
      sx={{ backgroundColor: 'background.paper' }}
    >
      <ToggleButton value={Mode.Availability}>Availability Mode</ToggleButton>
      <ToggleButton value={Mode.Appointment}>Appointment Mode</ToggleButton>
    </ToggleButtonGroup>
  )
}

export function FullCalendar({ calendarRef, events, date, businessHours, readOnly, onSelect, onClick, selectOverlap, selectAllow }) {
  useUpdateEffect(() => {
    calendarRef.current?.getApi()?.gotoDate(date.toDate())
  }, [date])

  useUpdateEffect(() => {
    calendarRef.current?.getApi()?.scrollToTime(businessHours?.start_time)
  }, [businessHours])

  return (
    <CalendarStyled id="scheduling-calendar">
      <FullCalendarBase
        ref={calendarRef}
        weekends
        nowIndicator
        events={events}
        initialDate={date.toDate()}
        scrollTime={businessHours?.start_time}
        defaultTimedEventDuration={slotDuration}
        slotDuration={slotDuration}
        slotLabelInterval="01:00:00"
        slotLabelFormat={{ hour: 'numeric', omitZeroMinute: true }}
        eventMinHeight={25}
        eventContent={getEventContent}
        forceEventDuration
        rerenderDelay={10}
        headerToolbar={false}
        allDaySlot={false}
        displayEventTime={false}
        selectable={!readOnly}
        selectMirror
        select={onSelect}
        eventClick={onClick}
        selectOverlap={selectOverlap}
        selectAllow={selectAllow}
        firstDay={-1}
        selectConstraint="businessHours"
        height={1100}
        businessHours={{
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          startTime: businessHours?.start_time,
          endTime: businessHours?.end_time,
        }}
        plugins={[timeGridPlugin, interactionPlugin]}
      />
    </CalendarStyled>
  )
}

const getEventContent = ({ event, isMirror }) => {
  const title = isMirror ? `${dayjs(event.start).format('LT')} - ${dayjs(event.end).format('LT')}` : event.title

  return (
    <Hover event={event}>
      <Box
        sx={{
          height: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: isMirror ? 'normal' : 'nowrap',
        }}
      >
        {title}
      </Box>
    </Hover>
  )
}

const Hover = styled(({ className, children, event, ...props }) => (
  <Tooltip placement="top" title={event.extendedProps.tooltip} followCursor {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))({
  '& .MuiTooltip-tooltip': {
    maxWidth: 500,
  },
})

function LegendItem({ label, color, background, borderColor = 'white' }) {
  return (
    <Box sx={{ borderRadius: 2, background, border: '2px solid', borderColor, p: 0.5 }}>
      <Typography color={color} align="center" fontWeight="bold" fontSize={14}>
        {label}
      </Typography>
    </Box>
  )
}
