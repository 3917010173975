import { useMemo } from 'react'
import Joyride, { EVENTS, STATUS } from 'react-joyride'
import merge from 'lodash/merge'
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles'

import useTourState from '@shared/hooks/src/useTourState'
import { useMe } from '@shared/providers/src/MeProvider'

import { CloseOutlinedIcon, RightOutlinedIcon } from '@icons'
import { Box, Button, IconButton, Paper, Stack, Typography } from '@mui-components'

export default function Tour(props) {
  const theme = useTheme()

  return (
    <Joyride
      continuous
      showProgress
      showSkipButton
      scrollOffset={150}
      tooltipComponent={TourTooltip}
      styles={{
        options: {
          arrowColor: theme.palette.primary.main,
          overlayColor: theme.palette.background.backdrop,
          zIndex: 1201,
        },
      }}
      {...props}
    />
  )
}

/** Handles the most common scenario for a tour */
export function TourBasic({ tourKey, steps, onFinish }) {
  const me = useMe()
  const { tourState, updateTourState, finishTour } = useTourState(me.id, tourKey)

  const handleCallback = ({ index, status, type }) => {
    // Tour finished
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      onFinish?.()
      return finishTour()
    }

    // Handle the rest of the steps
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      return updateTourState((p) => ({ ...p, stepIndex: index + 1 }))
    }
  }

  return <Tour run={tourState.run} stepIndex={tourState.stepIndex} steps={steps} callback={handleCallback} />
}

const styles = {
  container: {
    pt: 5,
    px: 2,
    pb: 1,
    position: 'relative',
    whiteSpace: 'pre-line',
    maxWidth: '100%',
    width: { xs: 330, sm: 430 },
  },
  close: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
}

/**
 * Component to render the tour tooltip
 *
 * continuous {boolean}: If the tour is continuous or not
 * index {number}: The current step's index
 * isLastStep {boolean}: The name says it all
 * size {number}: The number of steps in the tour
 * step {object}: The current step data
 * backProps {object}: The back button's props
 * closeProps {object}: The close button's props
 * primaryProps {object}: The primary button's props (Close or Next if the tour is continuous)
 * skipProps {object}: The skip button's props
 * tooltipProps {object}: The root element props (including ref)
 */
function TourTooltip({ continuous, index, isLastStep, size, step, backProps, closeProps, primaryProps, skipProps, tooltipProps }) {
  return (
    <ThemeOverride>
      <Paper elevation={6} sx={[styles.container, step.sx]} {...tooltipProps}>
        <IconButton color="inherit" sx={styles.close} {...skipProps}>
          <CloseOutlinedIcon />
        </IconButton>
        <Stack spacing={2}>
          {step.title && <Typography variant="h4">{step.title}</Typography>}
          {step.content && <Box>{step.content}</Box>}
          <Stack direction="row-reverse" justifyContent="space-between" alignItems="center" pt={2}>
            <Button color="inherit" endIcon={!isLastStep ? <RightOutlinedIcon /> : undefined} {...primaryProps}>
              {!isLastStep ? 'next' : 'Got it!'}
            </Button>
            <TourProgress size={size} index={index} />
          </Stack>
        </Stack>
      </Paper>
    </ThemeOverride>
  )
}

function TourProgress({ size, index }) {
  if (size <= 1) return null

  return (
    <Stack direction="row" spacing={1} px={1}>
      {Array.from({ length: size }).map((_, i) => (
        <Bubble key={i} fill={i <= index} />
      ))}
    </Stack>
  )
}

function Bubble({ fill }) {
  return (
    <Box
      sx={{
        width: 8,
        height: 8,
        borderRadius: '50%',
        border: (theme) => `1px solid ${theme.palette.primary.light}`,
        backgroundColor: (theme) => (fill ? theme.palette.primary.light : 'transparent'),
      }}
    />
  )
}

function ThemeOverride({ children }) {
  const baseTheme = useTheme()

  const theme = useMemo(() => {
    return createTheme(merge({}, baseTheme, themeOverride(baseTheme)))
  }, [baseTheme])

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

const themeOverride = (theme) => ({
  palette: {
    text: {
      primary: theme.palette.text.contrast,
      contrast: theme.palette.text.primary,
    },
    background: {
      paper: theme.palette.primary.main,
    },
  },
})
