import MUIDialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { CloseOutlinedIcon } from '@components/_icons'

import IconButton from './IconButton'

/**
 * Wrapper for MUI DialogTitle that adds a close button
 */
export default function DialogTitle(props) {
  return (
    <MUIDialogTitle {...props}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        {typeof props.children === 'string' ? (
          <Typography component="h2" variant="h4">
            {props.children}
          </Typography>
        ) : (
          props.children
        )}
        {props.onClose && (
          <IconButton color="secondary" edge="end" aria-label="close" onClick={props.onClose} data-testid="dialog-close">
            <CloseOutlinedIcon />
          </IconButton>
        )}
      </Stack>
    </MUIDialogTitle>
  )
}
