import { useMemo } from 'react'
import PropTypes from 'prop-types'
import useTheme from '@mui/styles/useTheme'

import { getTestId, LabStatus } from '@shared/utils'

import { CheckOutlinedIcon, CloseOutlinedIcon, ExclamationCircleOutlined, HourglassOutlinedIcon, InfoCircleOutlinedIcon } from '@icons'
import { Box, Typography } from '@mui-components'

const styles = {
  chip: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '12px',
    height: 24,
    minWidth: 24,
    px: 1.5,
    gap: 1,
    '& .MuiTypography-root': {
      fontWeight: 'bold',
    },
  },
}

StatusChip.propTypes = {
  type: PropTypes.oneOf(['secondary', 'success', 'warning', 'error', 'errorFilled', 'info', 'infoFilled']).isRequired,
  icon: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

const typeToIcon = {
  secondary: null,
  success: CheckOutlinedIcon,
  warning: HourglassOutlinedIcon,
  error: CloseOutlinedIcon,
  errorFilled: ExclamationCircleOutlined,
  info: InfoCircleOutlinedIcon,
  infoFilled: HourglassOutlinedIcon,
}

export default function StatusChip({ label, type, icon = false, ...rest }) {
  const theme = useTheme()

  const testId = getTestId(rest, `status-chip`)
  const { light, dark } = theme.palette.tag[type]
  const IconNode = typeToIcon[type]

  return (
    <Box sx={[styles.chip, { color: dark, backgroundColor: light }]} data-testid={testId} data-test-type={type}>
      {icon && IconNode && <IconNode style={{ fontSize: '12px' }} />}
      {label && (
        <Typography variant="caption" mt="2px" sx={{ whiteSpace: 'nowrap' }} data-testid={`${testId}-label`}>
          {label}
        </Typography>
      )}
    </Box>
  )
}

Rx.propTypes = {
  status: PropTypes.string,
}

/** TODO Ask BE to return the status in the same format */
function Rx({ status }) {
  const { label, type } = useMemo(() => {
    if (['pharmacy', 'At pharmacy'].includes(status)) return { label: status, type: 'warning' }
    if (status === 'Transit') return { label: status, type: 'warning' }
    if (['Unfilled', 'Error'].includes(status)) return { label: status, type: 'error' }
    if (['delivered', 'Delivered'].includes(status)) return { label: status, type: 'success' }
    if (status === 'Canceled') return { label: status, type: 'error' }
    if (status === 'Sent to pharmacy') return { label: status, type: 'warning' }
    if (status?.includes('tx:PENDING')) return { label: "Trf'd", type: 'warning' }
    return { label: status, type: 'info' }
  }, [status])

  return <StatusChip icon label={label} type={type} />
}

Lab.propTypes = {
  positive: PropTypes.bool,

  status: PropTypes.oneOf(Object.values(LabStatus)),
}

const LabStatusToType = {
  [LabStatus.Ordered]: 'warning',
  [LabStatus.NeedsReview]: 'info',
  [LabStatus.NurseReviewed]: 'info',
  [LabStatus.NeedsFollowUp]: 'info',
  [LabStatus.Ready]: 'success',
  [LabStatus.Released]: 'info',
  [LabStatus.OrderFailed]: 'error',
  [LabStatus.ShipmentPending]: 'warning',
  [LabStatus.Shipped]: 'warning',
  [LabStatus.InTransitionToPatient]: 'warning',
  [LabStatus.FailedDeliveryToPatient]: 'error',
  [LabStatus.DeliveredToPatient]: 'warning',
  [LabStatus.InTransitToLab]: 'warning',
  [LabStatus.ReceivedByLab]: 'warning',
  [LabStatus.ReturnedToLab]: 'warning',
  [LabStatus.LabProcessing]: 'warning',
  [LabStatus.LabRejected]: 'error',
  [LabStatus.Cancelled]: 'error',
  [LabStatus.Pending]: 'warning',
  [LabStatus.Requested]: 'warning',
}

function Lab({ status, positive = false, noLabel = false }) {
  if (positive) return <StatusChip icon label={noLabel ? '' : 'Positive'} type="errorFilled" />

  const label = noLabel ? '' : status
  const type = LabStatusToType[status] || 'info'

  return <StatusChip icon label={label} type={type} />
}

StatusChip.Rx = Rx
StatusChip.Lab = Lab
