import { keepPreviousData } from '@tanstack/react-query'

import { isUserAllowed, useMe } from '@shared/providers/src/MeProvider'
import { UserRole } from '@shared/utils'

import { Dialog, DialogContent, DialogTitle, Skeleton, Stack, Typography } from '@mui-components'
import CopyIconButton from '@components/CopyIconButton'
import { spacing } from '@components/Details/Details.utils'
import { usePatientAccessible } from '@components/PatientAccessGuard'

import { SchedTypeLabel } from '../Appointments.utils'
import { invalidateEncounterNotes, useAppointment } from './AppointmentDetailsModal.hooks'
import DetailsSection from './components/DetailsSection'
import NotesSection from './components/NotesSection'
import PatientSection from './components/PatientSection'

export default function AppointmentDetailsModal({ appointmentId, hidePatientData = false, open, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="paper"
      sx={{ '& .MuiDialog-container': { justifyContent: 'flex-end' } }}
    >
      <Content appointmentId={appointmentId} hidePatientData={hidePatientData} onClose={onClose} />
    </Dialog>
  )
}

function Content({ appointmentId, hidePatientData, onClose }) {
  const me = useMe()
  const { data } = useAppointment(appointmentId, {
    enabled: Boolean(appointmentId),
    placeholderData: keepPreviousData,
  })

  const patientAccessible = usePatientAccessible(data?.userId)
  const title = [SchedTypeLabel[data?.schedType], 'Appointment'].filter(Boolean).join(' ')
  const isAllowed = isUserAllowed(me, [UserRole.Admin, UserRole.ClinicalLead, UserRole.Provider, UserRole.MA, UserRole.Support])
  const canAddNotes = isAllowed && patientAccessible
  const showData = Boolean(data)

  return (
    <>
      <DialogTitle onClose={onClose}>
        {showData ? (
          <Stack spacing={-0.5}>
            <Stack direction="row" spacing={1}>
              <Typography fontWeight="normal" color="text.secondary">
                ID: {data.id}
              </Typography>
              <CopyIconButton fn={(copy) => copy(data.id)} />
            </Stack>
            <Typography component="h2" variant="h4">
              {title}
            </Typography>
          </Stack>
        ) : (
          <Stack spacing={-0.5}>
            <Typography>
              <Skeleton width={100} />
            </Typography>
            <Typography component="h2" variant="h4">
              <Skeleton width={300} />
            </Typography>
          </Stack>
        )}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={spacing}>
          {showData ? <DetailsSection appointment={data} /> : <DetailsSection.Loading />}
          {!hidePatientData && <PatientSection appointment={data} />}
          <NotesSection appointmentId={appointmentId} readOnly={!canAddNotes} onCreate={() => invalidateEncounterNotes(data)} />
        </Stack>
      </DialogContent>
    </>
  )
}
