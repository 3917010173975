import { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

import { getTestId } from '@shared/utils'

import { CaretDownOutlinedIcon } from '@icons'
import { Button, Checkbox, FormControlLabel, Menu, MenuItem, Stack, Typography } from '@mui-components'

MultiSelect.propTypes = {
  /** Label of the button */
  label: PropTypes.string.isRequired,

  /** Callback fired when the value changes */
  onChange: PropTypes.func,

  /** Options to display in the menu */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Label of the option */
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

      /** Description of the option */
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

      /** Value of the option */
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,

  /** Value of the component */
  value: PropTypes.array,
}

/**
 * Button with a dropdown menu that allows multiple selections
 *
 * @example
 * <MultiSelect
 *    label="Type"
 *    value={value}
 *    onChange={handleChange}
 *    options={[{ label: 'CBO', value: 'cbo' }, { label: 'Campaign', value: 'campaign' }]}
 * />
 */
export default function MultiSelect({ label, onChange, options, value = [], ...other }) {
  const anchorRef = useRef(null)
  const [openMenu, setOpenMenu] = useState(false)

  const testId = getTestId(other, `multi-select-${label}`)

  const handleValueChange = useCallback(
    (event) => {
      let newValue = [...value]

      if (event.target.checked) {
        newValue.push(event.target.value)
      } else {
        newValue = newValue.filter((item) => item != event.target.value)
      }

      onChange?.(newValue)
    },
    [onChange, value]
  )

  return (
    <>
      <Button
        color="inherit"
        endIcon={<DownIcon />}
        ref={anchorRef}
        onClick={() => setOpenMenu(true)}
        data-testid={`${testId}-button`}
        {...other}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        onClose={() => setOpenMenu(false)}
        open={openMenu}
        slotProps={{
          paper: {
            'data-testid': `${testId}-paper`,
            style: { width: 300 },
          },
        }}
      >
        {options.map((option) => {
          const itemTestId = `${testId}-item-${option.label}`
          const isSelected = value.includes(option.value)

          return (
            <MenuItem key={option.value} selected={isSelected} data-testid={itemTestId} data-test-active={isSelected}>
              <FormControlLabel
                control={<Checkbox checked={isSelected} onChange={handleValueChange} value={option.value} />}
                label={
                  <Stack spacing={-0.5}>
                    <Typography data-testid={`${itemTestId}-label`}>{option.label}</Typography>
                    {option.description && (
                      <Typography variant="body2" color="text.secondary" data-testid={`${itemTestId}-description`}>
                        {option.description}
                      </Typography>
                    )}
                  </Stack>
                }
                sx={{ flexGrow: 1, mr: 0 }}
              />
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

const DownIcon = styled(CaretDownOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))
